import { camelizeKeys, decamelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { getBaseFormQuery, getBaseUrl, ListRes } from "./utils";
import { StatusCreateRequestT, StatusCreateT, StatusFilterRequestT, StatusFilterT, StatusT } from "../models/Status";

const url = `${getBaseUrl()}/statuses`;
const tagType = "statuses";

export const statusesApi = createApi({
  reducerPath: "statusesApi",
  tagTypes: [tagType],
  baseQuery: getBaseFormQuery(url),
  endpoints: (build) => ({
    getStatuses: build.query<
      ListRes<StatusT>,
      { pagination: PaginationQuery, filter?: StatusFilterT, searchTerm?: string }
    >({
      query: ({ searchTerm, filter }) => {
        const formattedFilter: StatusFilterRequestT = {
          name: filter?.name,
          title: filter?.title,
          channelId: filter?.channel?.map((item) => item?.id).join(","),
        };
        return ({
          url: "list",
          method: "POST",
          body: decamelizeKeys({ searchTerm, filter: formattedFilter }),
        });
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<StatusT>) =>
        camelizeKeys(response) as ListRes<StatusT>,
    }),
    createItem: build.mutation<void, StatusCreateT>({
      query(data) {
        const sendingData: StatusCreateRequestT = {
          channelId: data.channel?.id ?? "",
          name: data.name ?? "",
          title: data.title ?? "",
        };
        return {
          url: "",
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useGetStatusesQuery,
  useLazyGetStatusesQuery,
  useCreateItemMutation,
} = statusesApi;
