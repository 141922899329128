import { decamelizeKeys, camelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { filterEmpyValues, getBaseQuery, getBaseUrl, ListRes, ModelRes } from "./utils";
import { WalletCreate, Wallet, WalletFilter, WalletCreateRequest, WithdrawalWalletType, WalletFilterRequest } from "../models/Wallet";

const url = `${getBaseUrl()}/wallets`;
const tagType = "wallets";

export const walletsApi = createApi({
  reducerPath: "walletsApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getWallets: build.query<
      ListRes<Wallet>,
      { pagination: PaginationQuery, filter?: WalletFilter, searchTerm?: string }
    >({
      query: ({ pagination, filter, searchTerm }) => {
        const sendingFilter = {
          ...filterEmpyValues({
            ...filter,
            address: filter?.address,
            currencyId: filter?.currency?.id,
            type: filter?.type?.value,
          }),
          hideNullableBalance: filter?.hideNullableBalance?.value,
          onCheck: filter?.onCheck?.value,
          status: filter?.status?.value,
        } as WalletFilterRequest;

        delete sendingFilter.currency;

        if (filter?.hideNullableBalance?.value === null) delete sendingFilter.hideNullableBalance;
        if (filter?.type?.value === null) delete sendingFilter.type;
        if (filter?.onCheck?.value === null) delete sendingFilter.onCheck;
        if (filter?.status?.value === null) delete sendingFilter.status;

        const data = { ...pagination, filter: sendingFilter, order_by: "status", searchTerm };
        if (!searchTerm) delete data.searchTerm;

        return {
          url: "list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Wallet>) =>
        camelizeKeys(response) as ListRes<Wallet>,
    }),
    getItem: build.query<ModelRes<Wallet>, string>({
      query: (id) => ({
        url: id,
        method: "GET",
      }),
      transformResponse: (response: ModelRes<Wallet>) =>
        camelizeKeys(response) as ModelRes<Wallet>,
    }),
    createItem: build.mutation<void, WalletCreate>({
      query: (data) => {
        const sendingData: WalletCreateRequest = {
          address: data.address,
          status: data.status,
          domain: data.domain,
          group: data.group,
          currencyId: data.currency?.id || null,
          onCheck: data.onCheck,
          type: data.type?.value || null,
        };
        return {
          url: "",
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    updateItem: build.mutation<void, { id: string, data: WalletCreate }>({
      query: ({ id, data }) => {
        const sendingData: WalletCreateRequest = {
          address: data.address,
          status: data.status,
          domain: data.domain,
          group: data.group,
          currencyId: data.currency?.id || null,
          onCheck: data.onCheck,
          type: data.type?.value || null,
        };
        return {
          url: "",
          method: "POST",
          body: decamelizeKeys({ ...sendingData, id }),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    deleteItem: build.mutation<void, string>({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    generateWallet: build.mutation<void, string>({
      query: (currencyId) => ({
        url: `/${currencyId}/generate`,
        method: "POST",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    withdrawalWallet: build.mutation<void, WithdrawalWalletType>({
      query: (data) => {
        if (!data.memo) delete data.memo;
        return ({
          url: "/withdrawal",
          method: "POST",
          body: decamelizeKeys(data),
        });
      },
    }),
  }),
});

export const {
  useGetWalletsQuery,
  useLazyGetWalletsQuery,
  useGetItemQuery,
  useCreateItemMutation,
  useUpdateItemMutation,
  useDeleteItemMutation,
  useGenerateWalletMutation,
  useWithdrawalWalletMutation,
} = walletsApi;
