import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../types/IField";
import { RetentionCreate, RetentionFilter } from "../models/Retention";
import { YupValidationFields } from "../types/global";

export const initialFilterValues: RetentionFilter = {
  comment: "",
  text: "",
};

export const getFilterFields = (): IAllFields<RetentionFilter>[] => [
  {
    name: "comment",
    type: TypesOfInput.TEXT,
  },
  {
    name: "text",
    type: TypesOfInput.TEXT,
  },
];

export const emptyInitialValues: RetentionCreate = {
  text: "",
  comment: "",
};

export const validationSchema = Yup.object<YupValidationFields<RetentionCreate>>({
  text: Yup.string().required("Required"),
  comment: Yup.string().required("Required"),
});

export const createFields = (): IAllFields<RetentionCreate>[] => [
  {
    name: "text",
    type: TypesOfInput.TEXT,
  },
  {
    name: "comment",
    type: TypesOfInput.TEXT,
  },
];
