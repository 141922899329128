import React, { useMemo } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageTitle from "../components/items/page-title";
import Field from "../components/form/formik/Field";
import {
  getWithdrawalFields,
  emptyWithdrawalWallet,
  withdrawalWalletvalidationSchema,
} from "../utils/walletsFields";
import { useWithdrawalWalletMutation } from "../api/wallets";
import { WithdrawalWalletType } from "../models/Wallet";
import { JSONTree } from "react-json-tree";

const ROUTE = "/wallets";

const WithdrawalWallet = () => {
  const fields = useMemo(() => getWithdrawalFields(), []);
  const [mutate, { data }] = useWithdrawalWalletMutation();

  const handleSubmit = (values: WithdrawalWalletType) => {
    mutate(values);
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to={ROUTE}>
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Withdrawal wallet" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={emptyWithdrawalWallet}
                onSubmit={handleSubmit}
                validationSchema={withdrawalWalletvalidationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
        <>
          {data && (
            <section className="mb-5">
              <div className="card">
                <div className="card-body">
                  <JSONTree
                    data={data}
                    theme={{ base00: "#fff" }}
                    invertTheme={false}
                    hideRoot={true}
                  />
                </div>
              </div>
            </section>
          )}
        </>
      </div>
    </div>
  );
};

export default WithdrawalWallet;
