import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import ColorLabel from "../components/items/ColorLabel";
import { Pair, typeOptions } from "../models/Pair";
import { Link } from "react-router-dom";

type IColumn = ColumnDef<Pair>;
const testImage = "https://bitslog.files.wordpress.com/2013/01/unknown-person1.gif";

interface Props {
  pagination: PaginationType;
}

export default function getColumnDefsPairs({
  pagination
}: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return (
        <div>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - index * pagination?.currentPage}
        </div>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "40px",
    id: "№",
  };

  const domain: IColumn = {
    Cell: (cell) => <Link to={`/pairs/${cell.row.original.id}`}>{cell.value}</Link>,
    Header: "Domain",
    id: "domain",
    accessor: "domain",
  };

  const currency: IColumn = {
    Header: "Currency",
    id: "currency",
    accessor: "currency",
    width: "auto",
  };

  const isSelectedPair: IColumn = {
    Cell: ({ value }) => <span>{value ? "On" : "Off"}</span>,
    Header: "Is selected",
    id: "isSelectedPair",
    accessor: "isSelectedPair",
    width: "auto",
  };

  const minMax: IColumn = {
    Cell: (cell) => <span>{cell.row.original.min + "/" + cell.value}</span>,
    Header: "Min/Max",
    id: "maxf",
    accessor: "max",
    width: "auto",
  };

  const percent: IColumn = {
    Header: "Percent",
    id: "percent",
    accessor: "percent",
    width: "auto",
  };

  const available: IColumn = {
    Header: "Available",
    id: "available",
    accessor: "available",
    width: "auto",
  };

  const type: IColumn = {
    Cell: ({ value }) => <ColorLabel colors={typeOptions} value={value} label={value} />,
    Header: "Type",
    id: "type",
    accessor: "type",
    width: "auto",
  };

  const user: IColumn = {
    Cell: ({ value }) =>
      value ? (
        <div className="d-flex align-items-center">
          <img
            src={value.avatar || testImage}
            alt="avatar"
            className="rounded-circle mr-3 w-30px h-30px"
          />
          <div>
            <p className="text-xs mb-0">{value.name}</p>
            <p className="text-xs">
              {value.telegramId}<br/>
              {value.username}
            </p>
          </div>
        </div>
      ) : null,
    Header: "User",
    id: "user",
    accessor: "client",
    width: "200px",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "auto",
  };

  return [numberIndex, domain, currency, user, isSelectedPair, available, percent, minMax, type, createdAt];
}
