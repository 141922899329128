import { decamelizeKeys, camelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { filterEmpyValues, getBaseQuery, getBaseUrl, ListRes, ModelRes } from "./utils";
import { RetentionCreate, Retention, RetentionFilter } from "../models/Retention";

const url = `${getBaseUrl()}/retentions`;
const tagType = "retentions";

export const retentionsApi = createApi({
  reducerPath: "retentionsApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getRetentions: build.query<
      ListRes<Retention>,
      { pagination: PaginationQuery, filter?: RetentionFilter, searchTerm?: string }
    >({
      query: ({ pagination, filter, searchTerm }) => {
        const data = {
          ...pagination,
          filter: filterEmpyValues(filter),
          searchTerm,
        };
        if (!searchTerm) delete data.searchTerm;

        return {
          url: "list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Retention>) =>
        camelizeKeys(response) as ListRes<Retention>,
    }),
    getItem: build.query<ModelRes<Retention>, string>({
      query: (id) => ({
        url: id,
        method: "GET",
      }),
      transformResponse: (response: ModelRes<Retention>) =>
        camelizeKeys(response) as ModelRes<Retention>,
    }),
    createItem: build.mutation<void, RetentionCreate>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: decamelizeKeys(data),
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    updateItem: build.mutation<void, { id: string, data: RetentionCreate }>({
      query: ({ id, data }) => ({
        url: "",
        method: "POST",
        body: decamelizeKeys({ ...data, id }),
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    deleteItem: build.mutation<void, string>({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useGetRetentionsQuery,
  useLazyGetRetentionsQuery,
  useGetItemQuery,
  useCreateItemMutation,
  useUpdateItemMutation,
  useDeleteItemMutation,
} = retentionsApi;
