import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../types/IField";
import { PixelCreate, PixelFilter, sourceOptions } from "../models/Pixel";
import { YupValidationFields } from "../types/global";

export const emptyFilterValues: PixelFilter = {
  pixel: "",
  token: "",
  source: null,
};

export const emptyInitialValues: PixelCreate = {
  ...emptyFilterValues,
};

export const validationSchema = Yup.object<YupValidationFields<PixelCreate>>({
  pixel: Yup.string().required("Required"),
  token: Yup.string().required("Required"),
  source: Yup.object().typeError("Required"),
});

export const getCreateFields = (): IAllFields<PixelCreate>[] => [
  {
    name: "pixel",
    type: TypesOfInput.TEXT,
  },
  {
    name: "token",
    type: TypesOfInput.TEXT,
  },
  {
    name: "source",
    type: TypesOfInput.SELECT,
    options: sourceOptions,
  },
];

export const getFilterFields = (): IAllFields<PixelFilter>[] => getCreateFields();
