import { LabelColors, ListItem, Modify, TypeOptions } from "../types/global";
import { BoolOptionsType, OptionModel } from "../types/ISelect";
import { Currency } from "./Currency";

export type WalletType = "basic" | "retention";

export type WalletGeneral = {
  address: string,
  domain: string | null,
  group: string | null,
  currency: OptionModel<Currency>,
  onCheck: boolean,
  type: WalletType | null;
}

export type Wallet = ListItem<WalletGeneral> & {
  qr: string,
  used: number,
  balance: number,
  status: 0 | 1,
}

export type WalletCreate = Modify<WalletGeneral, {
  status: boolean,
  type: OptionModel<TypeOptions<WalletType>>,
}>;

export type WalletCreateRequest = {
  currencyId: OptionModel<string>,
  address: string,
  status: boolean,
  group: null | string,
  domain: null | string,
  onCheck: boolean,
  type: WalletType | null;
}

export type WalletFilter = Modify<WalletGeneral, {
  hideNullableBalance: BoolOptionsType,
  status: BoolOptionsType,
  type: BoolOptionsType,
  onCheck: BoolOptionsType,
}>

export type WalletFilterRequest = Modify<WalletGeneral, {
  hideNullableBalance?: boolean | null,
  status?: boolean | null,
  type?: boolean | null,
  onCheck?: boolean | null,
  searchTerm?: string,
  currency?: null,
}>

export type GenerateWallet = {
  currency: OptionModel<Currency>,
}

export type WithdrawalWalletType = {
  token: string,
  blockchain: string,
  address: string,
  memo?: string,
}

export const typeOptions: TypeOptions<WalletType>[] = [
  {
    color: LabelColors.BLUE,
    label: "Basic",
    value: "basic",
  },
  {
    color: LabelColors.ORANGE,
    label: "Retention",
    value: "retention",
  },
];
