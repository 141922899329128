import React, { useMemo } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../components/items/page-title";
import Field from "../components/form/formik/Field";
import { getCreateFields, emptyInitialValues, validationSchema } from "../utils/botLinksFields";
import { BotLinkCreate } from "../models/BotLink";
import { useCreateItemMutation } from "../api/botLinks";
import { useGetBotsQuery } from "../api/bots";
import { useGetPixelsQuery } from "../api/pixels";
import { useGetUsersQuery } from "../api/users";

const CreateBotLink = () => {
  const navigate = useNavigate();
  const fields = useMemo(() => getCreateFields({
    usersQuery: useGetUsersQuery,
    pixelsQuery: useGetPixelsQuery,
    botsQuery: useGetBotsQuery,
  }), [useGetBotsQuery, useGetPixelsQuery, useGetUsersQuery]);

  const [createBotLink, { isLoading }] = useCreateItemMutation();

  const handleSubmit = async (values: BotLinkCreate) => {
    createBotLink(values).unwrap().then(() => navigate("/bot-links"));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/bot-links">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating botLink" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={emptyInitialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                    disabled={isLoading}
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateBotLink;
