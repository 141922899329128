import * as Yup from "yup";
import { IAllFields } from "../types/IField";
import { ChannelCreate, ChannelFilter } from "../models/Channel";
import { YupValidationFields } from "../types/global";
import { fieldsConstructor } from "./formats";

export const emptyInitialValues: ChannelFilter = {
  channelId: "",
  cr: "",
  cvr: "",
  logo: "",
  name: "",
  revenue: "",
  username: "",
  dialogs: 0,
  fd: 0,
  retention: 0,
  subscribers: 0,
};

export const validationSchema = Yup.object<YupValidationFields<ChannelCreate>>({
  channelId: Yup.string(),
  cr: Yup.string(),
  cvr: Yup.string(),
  logo: Yup.string(),
  name: Yup.string(),
  revenue: Yup.string(),
  username: Yup.string(),
  dialogs: Yup.number(),
  fd: Yup.number(),
  retention: Yup.number(),
  subscribers: Yup.number(),
});

export const getCreateFields = (): IAllFields<ChannelCreate>[] => fieldsConstructor<ChannelCreate>([
  "channelId",
  "cr",
  "cvr",
  "logo",
  "name",
  "revenue",
  "username",
  "dialogs",
  "fd",
  "retention",
  "subscribers",
]);
