import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { Pixel } from "../models/Pixel";

type IColumn = ColumnDef<Pixel>;

interface Props {
  pagination: PaginationType;
}

export default function getColumnDefsPixels({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row }) => (
      <span>
        {pagination.total &&
          pagination.currentPage &&
          pagination.total - row.index * pagination?.currentPage}
      </span>
    ),
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    width: "20px",
    id: "№",
  };

  const pixel: IColumn = {
    Header: "Pixel",
    id: "pixel",
    accessor: "pixel",
    width: "50px",
  };

  const token: IColumn = {
    Header: "Token",
    id: "token",
    accessor: "token",
    width: "100px",
  };

  const source: IColumn = {
    Header: "Source",
    id: "source",
    accessor: "source",
    width: "50px",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "80px",
  };

  return [numberIndex, pixel, token, source, createdAt];
}
