import React from "react";
import { useField } from "formik";
import CreatableSelect from "react-select/creatable";
import { ISelect } from "../../../../types/ISelect";
import { UnknownRecord } from "../../../../types/global";
import { SingleValue } from "react-select";

const CustomSelect = <T extends UnknownRecord>(props: ISelect<T>) => {
  const [field, , helpers] = useField<string>(props.name);
  const { setValue } = helpers;
  const value = props.options.find((option) => option[props.valueName] === field.value) || null;

  return (
    <CreatableSelect
      {...field}
      {...props}
      value={value}
      onChange={(option: SingleValue<UnknownRecord>) => {
        if (!option || !option[props.valueName]) {
          return setValue("");
        }

        if (option[props.valueName]) {
          setValue(option[props.valueName] as string);
        }
      }}
      isClearable
      isMulti={false}
    />
  );
};

export default CustomSelect;
