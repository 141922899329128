import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../types/IField";
import { OptionsQuery, YupValidationFields } from "../types/global";
import { CreateInvitationType } from "../models/Invitation";

export const initialValues: CreateInvitationType = {
  source: "",
  user: null,
  channel: null,
};

export const getValidationSchema = (isAdmin: boolean) => {
  if (isAdmin) {
    return Yup.object<YupValidationFields<CreateInvitationType>>({
      source: Yup.string().required(),
      channel: Yup.object().typeError("Required"),
      user: Yup.object().typeError("Required"),
    });
  }

  return Yup.object<YupValidationFields<CreateInvitationType>>({
    source: Yup.string().required(),
    channel: Yup.object().typeError("Required"),
  });
};

type Props = {
  usersQuery: OptionsQuery;
  channelsQuery: OptionsQuery;
}

export const getCreateFields = ({ usersQuery, channelsQuery }: Props): IAllFields<CreateInvitationType>[] => [
  {
    label: "Source",
    name: "source",
    type: TypesOfInput.TEXT,
  },
  {
    label: "User",
    query: usersQuery,
    name: "user",
    type: TypesOfInput.SELECT,
    labelName: "affiliate",
    valueName: "id",
  },
  {
    label: "Channel",
    query: channelsQuery,
    name: "channel",
    type: TypesOfInput.SELECT,
    labelName: "name",
    valueName: "channelId",
  },
];
