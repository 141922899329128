import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../types/IField";
import { SiteCreate, SiteFilter, statusOptions } from "../models/Site";
import { TypeOptions, YupValidationFields } from "../types/global";
import { ISelect, boolOptions } from "../types/ISelect";

export const emptyFilterValues: SiteFilter = {
  domain: "",
  name: "",
  support: "",
  status: statusOptions[1],
};

export const emptyInitialValues: SiteCreate = {
  ...emptyFilterValues,
  logo: null,
};

export const validationSchema = Yup.object<YupValidationFields<SiteCreate>>({
  domain: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  support: Yup.string().required("Required"),
  status: Yup.object().typeError("Required"),
  logo: Yup.mixed()
  .test("fileSize", "Required", (value) => {
    if (!value && typeof value !== "string") return false;
    return true;
  })
  .test("fileSize", "The file is too large", (value) => {
    if (typeof value === "string") return true;
    if (!value) return false;
    return value.size <= 2000000;
  }),
});

export const getCreateFields = (): IAllFields<SiteCreate>[] => [
  {
    name: "domain",
    type: TypesOfInput.TEXT,
  },
  {
    name: "name",
    type: TypesOfInput.TEXT,
  },
  {
    name: "support",
    type: TypesOfInput.TEXT,
  },
  {
    name: "status",
    type: TypesOfInput.SELECT,
    options: statusOptions as ISelect<TypeOptions<boolean | null>>["options"],
  },
  {
    name: "logo",
    type: TypesOfInput.FILE,
  },
];

export const getFilterFields = (): IAllFields<SiteFilter>[] => [
  {
    name: "domain",
    type: TypesOfInput.TEXT,
  },
  {
    name: "name",
    type: TypesOfInput.TEXT,
  },
  {
    name: "support",
    type: TypesOfInput.TEXT,
  },
  {
    name: "status",
    type: TypesOfInput.SELECT,
    options: boolOptions as ISelect<TypeOptions<boolean | null>>["options"],
  },
];
