import { OptionModel } from "../types/ISelect";
import { LabelColors, ListItem, Modify, TypeOptions } from "../types/global";

export type PixelSourceType = "tt" | "google" | "facebook";

export type Pixel = ListItem<Modify<PixelFilter, { source: PixelSourceType }>>

export type PixelFilter = {
  pixel: string,
  token: string,
  source: OptionModel<TypeOptions<PixelSourceType>>
}

export type PixelCreate = PixelFilter

export type PixelCreateRequest = Modify<PixelCreate, {
  source: PixelSourceType,
}>

export const sourceOptions: TypeOptions<PixelSourceType>[] = [
  {
    color: LabelColors.BLUE,
    label: "Facebook",
    value: "facebook",
  },
  {
    color: LabelColors.GREEN,
    label: "Google",
    value: "google",
  },
  {
    color: LabelColors.BLUE_LIGHT,
    label: "tt",
    value: "tt",
  },
];
