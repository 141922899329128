import React from "react";
import { Formik, Form } from "formik";
import { OrderByFormType, TypeOptions, UnknownRecord } from "../../../types/global";
import Select, { SingleValue } from "react-select";
import { Button, ButtonGroup } from "react-bootstrap";
import { formatOptions } from "../../../utils/formats";

interface Props<M> {
  handleFetch: (values: OrderByFormType) => void;
  orderByKeys: (keyof M)[];
  values: OrderByFormType,
}

const OrderByForm = <M extends UnknownRecord>({ handleFetch, orderByKeys, values }: Props<M>) => (
  <div className="col-5 mb-3 d-flex align-items-center">
    <span className="mr-3">Order by:</span>
    <Formik
      initialValues={values as OrderByFormType}
      onSubmit={() => {}}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className="d-flex align-items-center">
            <Select
              options={formatOptions(orderByKeys) as TypeOptions<string>[]}
              value={values.orderBy}
              onChange={(option: SingleValue<TypeOptions<string>>) => {
                if (!option) return;
                setFieldValue("orderBy", option);
                handleFetch({ sort: values.sort, orderBy: option });
              }}
            />
            <ButtonGroup>
              <Button
                variant={values.sort === "asc" ? "primary" : "outline-secondary"}
                onClick={() => {
                  handleFetch({ sort: "asc", orderBy: values.orderBy });
                  setFieldValue("sort", "asc");
                }}
              >
                &uarr;
              </Button>
              <Button
                variant={values.sort !== "asc" ? "primary" : "outline-secondary"}
                onClick={() => {
                  handleFetch({ sort: "desc", orderBy: values.orderBy });
                  setFieldValue("sort", "desc");
                }}
              >
                &darr;
              </Button>
            </ButtonGroup>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

export default OrderByForm;
