import React, { useMemo, useEffect, useState, useCallback } from "react";
import Loading from "./Loading";
import { useLazyGetDiagramStatisticsQuery } from "../api/statistics";
import {
  emptyStatisticsDiagramFilterValues,
  getFilterDiagramFields,
} from "../utils/statisticsFields";
import { DiagramStatistic, StatisticDiagramFilter } from "../models/Statistic";
import FilterForm from "./form/formik/FilterForm";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const StatisticsDiagram = () => {
  const [filter, setFilter] = useState<StatisticDiagramFilter>(emptyStatisticsDiagramFilterValues);

  const [fetch, query] = useLazyGetDiagramStatisticsQuery();
  const { data, isLoading } = query;

  const totalItems =  useMemo(() => {
    if (!data) return [];
    const total = data.reduce((prev, curr) => prev + curr.total, 0);
    return data.map((item) => `${((item.total * 100) / total).toFixed(1)}%`) ?? [];
  }, [data]);

  const formatData = useCallback(
    (data: DiagramStatistic) => {
      const labels = data.map((item, index) => `${item.progress} - ${item.total} ( ${totalItems[index]} )`) ?? [];
      const dataItems = data.map((item) => item.total) ?? [];
      return {
        labels: [...labels, `Total - ${data.reduce((prev, curr) => prev + curr.total, 0)} ( 100% )`],
        datasets: [
          {
            data: dataItems,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };
    },
    [data],
  );

  useEffect(() => {
    fetch({ filter });
  }, [filter]);

  const filterFields = useMemo(() => getFilterDiagramFields(), []);

  const handleFilter = (values: StatisticDiagramFilter) => {
    setFilter(values);
    return false;
  };

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <>
      <FilterForm
        className="mb-3"
        fields={filterFields}
        handleSubmit={handleFilter}
        initialValues={emptyStatisticsDiagramFilterValues}
      />
      <div className="row">
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Pie
                data={formatData(data)}
                width={100}
                height={300}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (item) => `${item.label}`
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default StatisticsDiagram;
