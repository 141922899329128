import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { Currency } from "../models/Currency";
import { Link } from "react-router-dom";

type IColumn = ColumnDef<Currency>;

interface Props {
  pagination: PaginationType;
}

export default function ({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row, value }) => {
      return (
        <Link to={`/currencies/${value}`}>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - row.index * pagination?.currentPage}
        </Link>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    width: "20px",
    id: "№",
  };

  const name: IColumn = {
    Header: "Name",
    id: "Name",
    accessor: "name",
    width: "50px",
  };

  const available: IColumn = {
    Header: "available",
    id: "available",
    accessor: "available",
    width: "50px",
  };

  const label: IColumn = {
    Header: "label",
    id: "label",
    accessor: "label",
    width: "50px",
  };

  const network: IColumn = {
    Header: "network",
    id: "network",
    accessor: "network",
    width: "50px",
  };

  const price: IColumn = {
    Header: "price",
    id: "price",
    accessor: "price",
    width: "50px",
  };

  const symbol: IColumn = {
    Header: "symbol",
    id: "symbol",
    accessor: "symbol",
    width: "50px",
  };

  const balance: IColumn = {
    Header: "balance",
    id: "balance",
    accessor: "balance",
    width: "50px",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "50px",
  };

  return [numberIndex, name, available, label, network, price, symbol, balance, createdAt];
}
