import { TransactionFilterForm } from "../models/Transaction";
import { IAllFields, TypesOfInput } from "../types/IField";
import { OptionsQuery } from "../types/global";
import { formatOptions } from "./formats";

export const initialFilterValues: TransactionFilterForm = {
  hash: "",
  paymentHash: "",
  code: "",
  addressSending: "",
  transactionId: null,
  domain: "",
  clientId: null,
  statuses: [],
};

type Props = {
  clientsQuery: OptionsQuery;
  statusesQuery: OptionsQuery;
}

export const getFilterFields = ({ clientsQuery, statusesQuery }: Props): IAllFields<TransactionFilterForm>[] => [
  {
    name: "hash",
    type: TypesOfInput.TEXT,
  },
  {
    name: "paymentHash",
    type: TypesOfInput.TEXT,
  },
  {
    name: "code",
    type: TypesOfInput.TEXT,
  },
  {
    name: "addressSending",
    type: TypesOfInput.TEXT,
  },
  {
    name: "domain",
    type: TypesOfInput.TEXT,
  },
  {
    query: clientsQuery,
    name: "clientId",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "telegramId",
    isMulti: true,
  },
  {
    query: statusesQuery,
    name: "statuses",
    type: TypesOfInput.SELECT,
    formatOptions: (data) => formatOptions(Object.keys(data)),
    withoutPagination: true,
    isMulti: true,
    isAllButton: true,
  },
];
