import { camelizeKeys, decamelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { getBaseFormQuery, getBaseUrl, ListRes, ModelRes } from "./utils";
import { Site, SiteCreate, SiteCreateRequest, SiteFilter } from "../models/Site";
import { serialize } from "object-to-formdata";

const url = `${getBaseUrl()}/sites`;
const tagType = "sites";

export const sitesApi = createApi({
  reducerPath: "sitesApi",
  tagTypes: [tagType],
  baseQuery: getBaseFormQuery(url),
  endpoints: (build) => ({
    getSites: build.query<
      ListRes<Site>,
      { pagination: PaginationQuery, filter?: SiteFilter, searchTerm?: string }
    >({
      query: ({ searchTerm }) => ({
        url: "list",
        method: "POST",
        body: decamelizeKeys({ searchTerm }),
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Site>) =>
        camelizeKeys(response) as ListRes<Site>,
    }),
    getItem: build.query<ModelRes<Site>, string>({
      query: (id) => ({
        url: id,
        method: "GET",
      }),
      transformResponse: (response: ModelRes<Site>) =>
        camelizeKeys(response) as ModelRes<Site>,
      providesTags: (result) => [{ type: tagType, id: result?.model.id }]
    }),
    createItem: build.mutation<void, SiteCreate>({
      query(data) {
        const sendingData: SiteCreateRequest = {
          ...data,
          status: data.status.value === true ? 1 : 0,
        };
        return {
          url: "",
          method: "POST",
          body: serialize(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    updateItem: build.mutation<ModelRes<Site>, { id: string, data: SiteCreate }>({
      query: ({ id, data }) => {
        const sendingData: SiteCreateRequest = {
          ...data,
          status: data.status.value === true ? 1 : 0,
        };
        return {
          url: id,
          method: "POST",
          body: serialize(sendingData),
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: tagType, id: arg.id }],
    }),
    deleteItem: build.mutation<void, string>({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useGetSitesQuery,
  useGetItemQuery,
  useLazyGetSitesQuery,
  useUpdateItemMutation,
  useCreateItemMutation,
  useDeleteItemMutation,
} = sitesApi;
