import React, { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Loading from "../components/Loading";

const AdminLayout = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <>
      <Header toggleMenuHandle={() => setToggleMenu((prevState) => !prevState)} />
      <div className="d-flex align-items-stretch">
        <SideBar toggleMenu={toggleMenu} />
        <div className="page-holder bg-gray-100">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>

          <footer className="footer bg-white shadow align-self-end py-3 px-xl-5 w-100">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 text-center text-md-start text-primary">
                  <p className="mb-2 mb-md-0">Your company © {new Date().getFullYear()}</p>
                </div>
                <div className="col-md-6 text-center text-md-end text-gray-400">
                  <p className="mb-0">Version 1.0.0</p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
