import { camelizeKeys, decamelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { filterEmpyValues, getBaseFormQuery, getBaseUrl, ListRes } from "./utils";
import { Bot, BotCreate, BotFilter } from "../models/Bot";

const url = `${getBaseUrl()}/bots`;
const tagType = "bots";

export const botsApi = createApi({
  reducerPath: "botsApi",
  tagTypes: [tagType],
  baseQuery: getBaseFormQuery(url),
  endpoints: (build) => ({
    getBots: build.query<
      ListRes<Bot>,
      { pagination: PaginationQuery, filter?: BotFilter, searchTerm?: string }
    >({
      query: (data) => ({
        url: "list",
        method: "POST",
        body: decamelizeKeys({
          ...data.pagination,
          filter: filterEmpyValues(data.filter),
        }),
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Bot>) =>
        camelizeKeys(response) as ListRes<Bot>,
    }),
    createItem: build.mutation<void, BotCreate>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useGetBotsQuery,
  useLazyGetBotsQuery,
  useCreateItemMutation,
} = botsApi;
