import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { Wallet } from "../models/Wallet";
import { Link } from "react-router-dom";
import { handleCopy } from "../api/utils";
import Label from "../components/items/Label";

type IColumn = ColumnDef<Wallet>;

interface Props {
  pagination: PaginationType;
}

const formatAddress = (address: string, characters = 7) => (
  `${address.slice(0, characters)}...${address.slice(-characters)}`
);

export default function getColumnDefsWallets({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row, value }) => {
      return (
        <Link to={`/wallets/${value}`}>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - row.index * pagination?.currentPage}
        </Link>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    width: "20px",
    id: "№",
  };

  const currency: IColumn = {
    Cell: ({ value }) => <span>{value ? `${value.name}` : "No"}</span>,
    Header: "Currency",
    id: "currency",
    accessor: "currency",
    width: "80px",
  };

  const address: IColumn = {
    Cell: ({ value }) => (
      <div className="p-1 btn-link">
        <span onClick={() => handleCopy(value || "")} role="button">
          {formatAddress(value)}
        </span>
      </div>
    ),
    Header: "Address",
    id: "address",
    accessor: "address",
    width: "90px",
  };

  const qr: IColumn = {
    Cell: ({ value }) => <img className="svg-icon-lg" src={value} alt="qr" />,
    Header: "Qr",
    id: "qr",
    accessor: "qr",
    width: "50px",
  };

  const used: IColumn = {
    Header: "Used",
    id: "used",
    accessor: "used",
    width: "50px",
  };

  const group: IColumn = {
    Header: "Group",
    id: "group",
    accessor: "group",
    width: "100px",
  };

  const domain: IColumn = {
    Header: "Domain",
    id: "domain",
    accessor: "domain",
    width: "50px",
  };

  const balance: IColumn = {
    Header: "Balance",
    id: "balance",
    accessor: "balance",
    width: "70px",
  };

  const type: IColumn = {
    Header: "Type",
    id: "type",
    accessor: "type",
    width: "50px",
  };

  const onCheck: IColumn = {
    Cell: ({ value }) => <Label status={Boolean(value)} />,
    Header: "On check",
    id: "onCheck",
    accessor: "onCheck",
    width: "50px",
  };

  const status: IColumn = {
    Cell: ({ value }) => <Label status={Boolean(value)} />,
    Header: "Status",
    id: "status",
    accessor: "status",
    width: "50px",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "80px",
  };

  return [numberIndex, group, domain, currency, balance, used, address, qr, type, onCheck, status, createdAt];
}
