import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../types/IField";
import { StatusCreateT, StatusFilterT } from "../models/Status";
import { OptionsQuery, YupValidationFields } from "../types/global";

export const emptyFilterValues: StatusFilterT = {
  channel: [],
  name: "",
  title: "",
};

export const emptyInitialValues: StatusCreateT = {
  channel: null,
  name: "",
  title: "",
};

export const validationSchema = Yup.object<YupValidationFields<StatusCreateT>>({
  channel: Yup.object().typeError("Required"),
  name: Yup.string().typeError("Required"),
  title: Yup.string().typeError("Required"),
});

type Props = {
  channelQuery: OptionsQuery;
}

export const getCreateFields = ({
  channelQuery
}: Props): IAllFields<StatusCreateT>[] => [
  {
    query: channelQuery,
    name: "channel",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name"
  },
  {
    name: "name",
    type: TypesOfInput.TEXT,
  },
  {
    name: "title",
    type: TypesOfInput.TEXT,
  },
];

export const getFilterFields = ({ channelQuery }: Props): IAllFields<StatusFilterT>[] => [
  {
    query: channelQuery,
    name: "channel",
    type: TypesOfInput.SELECT,
    isMulti: true,
    valueName: "id",
    labelName: "name"
  },
  {
    name: "name",
    type: TypesOfInput.TEXT,
  },
  {
    name: "title",
    type: TypesOfInput.TEXT,
  },
];
