import React from "react";
import cn from "classnames";
import { LabelColors, TypeOptions } from "../../types/global";

interface Props<T extends string> {
  colors?: TypeOptions<T>[];
  color?: LabelColors | string;
  value?: T;
  label?: string;
  isSingleColor?: boolean;
  className?: string;
  onClick?: VoidFunction;
}

const ColorLabel = <T extends string>({
  colors,
  value,
  label,
  isSingleColor,
  color,
  className,
  onClick,
}: Props<T>) => {
  const selectedColor: TypeOptions<T> | undefined =
    colors && colors.find((color: TypeOptions<T>) => color.value === value);
  const currentColor: T | unknown | string = !isSingleColor ? selectedColor?.color : color;

  return (
    <div className={cn("ColorLabel", className)} onClick={onClick}>
      <span
        className="ColorLabel__color"
        style={{ backgroundColor: (currentColor as string) || LabelColors.GRAY }}
      >
        <span className="p-2">{label}</span>
      </span>
    </div>
  );
};

export default ColorLabel;
