import React, { useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { Client, ShortEditClient } from "../models/Client";
import { useGetHistoryQuery, useGetItemQuery, useShortUpdateItemMutation } from "../api/clients";
import { formatDate, formatDateAndTime } from "../utils/formats";
import Table from "../components/tables/Table";
import getTransactionHistory from "../utils/transactionHistoryColumn";
import ClientActions from "../components/ClientActions";
import { useDeleteCommentMutation, useGetClientCommentsQuery } from "../api/transactions";
import { useAppDispatch } from "../hooks/redux";
import CreateCommentModal from "../components/CreateCommentModal";
import ChangeShortUserInfo from "../components/ChangeShortUserInfo";
import { FormikProps } from "formik";

const ROUTE = "/clients";
const testImage = "https://bitslog.files.wordpress.com/2013/01/unknown-person1.gif";

const UpdateClient = () => {
  const dispatch = useAppDispatch();
  const params = useParams() as { id: string };
  const [showModal, setShowModal] = useState(false);
  const formikRef: React.Ref<FormikProps<ShortEditClient>> | null = useRef(null);
  const { data, isLoading, isSuccess } = useGetItemQuery(params.id);
  const [deleteComment, { isLoading: isLoadingDelete }] = useDeleteCommentMutation();
  const item = useMemo(() => (isSuccess ? data.model : null), [isSuccess, data]);
  const commentsQuery = useGetClientCommentsQuery(params.id);
  const historyQuery = useGetHistoryQuery(params.id);
  const [, { isLoading: isLoadingShortUpdate }] = useShortUpdateItemMutation();

  const comments = useMemo(
    () => (commentsQuery.isLoading ? [] : commentsQuery.data?.list || []),
    [commentsQuery],
  );

  const histories = useMemo(
    () => (historyQuery.isLoading ? [] : historyQuery.data?.list || []),
    [historyQuery],
  );

  const handleDelete = (id: string) => {
    deleteComment(id)
      .unwrap()
      .then(() => {
        dispatch({
          type: "clientsApi/invalidateTags",
          payload: [{ type: "clients", id: params.id }],
        });
      });
  };

  const renderAmount = (amount: number) =>
    Number(amount) > 0 ? (
      <span className="color-green">+{amount}</span>
    ) : (
      <span className="color-red">{amount}</span>
    );

  const extraFieldNames: (keyof Client)[] = [
    "affiliate",
    "email",
    "ip",
    "isPremium",
    "language",
    "notificationStatus",
    "status",
    "invite",
    "inviteName",
    "inviteLink",
    "source",
    "pass",
  ];

  const extraFieldNamesDates: (keyof Client)[] = [
    "fdDate",
    "messageAt",
    "retentionDate",
    "createdAt",
  ];

  const columns = useMemo(() => getTransactionHistory(), []);

  if (isLoading || !item) return <Loading />;

  return (
    <div className="container-fluid px-lg-4 px-xl-5 mb-10">
      <div className="row">
        <Link to={ROUTE}>
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <Row>
          <Col xs={12} md={5}>
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <img className="w-25" src={item.avatar || testImage} alt="user avatar" />
                  <p>{item.name}</p>
                  <p>{item.username}</p>
                  <p>{item.telegramId}</p>
                  <p>{renderAmount(item.balance)}</p>
                </div>
                <hr />

                {!!item.subscriptions.length && (
                  <>
                    <p className="fw-bold">Subscribe</p>
                    <Row>
                      {item.subscriptions.map((subscription) => (
                        <div key={subscription.id} className="flex">
                          <img
                            src={subscription.channel.logo}
                            alt="logo"
                            className="mr-2 w-25 h-25"
                          />
                          <div>
                            <p
                              className={`${
                                subscription.unsubscribedAt ? "color-red" : "color-green"
                              }`}
                            >
                              {subscription.channel.name}
                            </p>
                            {subscription.unsubscribedAt ? (
                              <p>{subscription.unsubscribedAt}</p>
                            ) : (
                              <p>{formatDate(subscription.createdAt)}</p>
                            )}
                          </div>
                        </div>
                      ))}
                    </Row>
                    <hr />
                  </>
                )}

                <p className="fw-bold">Info</p>
                {extraFieldNames.map((field) => (
                  <div key={field} className="mb-1">{`${field}: ${item[field]}`}</div>
                ))}
                {extraFieldNamesDates.map((field) => (
                  <div key={field} className="mb-1">{`${field}: ${
                    item[field] ? formatDateAndTime(item[field] as string) : item[field]
                  }`}
                  </div>
                ))}
                <div className="mb-1">visit: {formatDate(item.visit)}</div>
                <hr />

                {item.retention && (
                  <>
                    <p className="fw-bold">Retention</p>
                    <div className="mb-1">comment: {item.retention?.comment}</div>
                    <div className="mb-1">comment: {item.retention?.text}</div>
                    <div className="mb-1">created at: {formatDate(item.retention?.createdAt)}</div>
                    <div className="mb-1">retention date: {item.retentionDate}</div>
                  </>
                )}
                <ChangeShortUserInfo
                  values={item}
                  clientId={params.id}
                  formikRef={formikRef}
                  type="model"
                  payload={[{ type: "clients", id: params.id }]}
                />
                <Button
                  className="w-full"
                  disabled={isLoadingShortUpdate}
                  onClick={() => formikRef.current?.handleSubmit()}
                >
                  Change info
                </Button>
              </div>
              <div className="card-footer">
                <ClientActions client={item} />
              </div>
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="card mb-3">
              <div className="card-header">Transactions</div>
              <div className="card-body">
                <Table
                  className="min-w-screen-lg"
                  columns={columns}
                  containerClass="overflow-auto"
                  fixed
                  items={item.transactions}
                  dataKey="transactions"
                  setPagination={() => {}}
                />
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-header">Comments</div>
              <div className="card-body">
                <Row>
                  {comments.map((comment, index) => (
                    <Col key={index} xs={12} md={6} lg={4}>
                      <div className="card">
                        <div className="card-body">
                          <p>Text: {comment.text}</p>
                          <p>Date: {formatDate(comment.createdAt)}</p>
                          <p>User: {comment?.user?.name}</p>
                          <p>User date: {formatDateAndTime(comment?.user?.createdAt)}</p>
                          <Button
                            className="w-auto mb-3"
                            variant="danger"
                            disabled={isLoadingDelete}
                            onClick={() => handleDelete(comment.id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="card-footer">
                <Button className="w-full" onClick={() => setShowModal(true)}>Create comment</Button>
              </div>
              <CreateCommentModal
                setShowModal={setShowModal}
                showModal={showModal}
                clientId={params.id}
                payload={[{ type: "clients", id: params.id }]}
              />
            </div>
            <div className="card">
              <div className="card-header">Progress history</div>
              <div className="card-body">
                <ul>
                  {histories.map((history) => (
                    <li key={history.id}>
                      {history.progress} ({formatDate(history.createdAt)}) - {history.user.email}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateClient;
