import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../types/IField";
import { ClientCreate, ClientFilter, clientProgressOptions, statusOptions } from "../models/Client";
import {OptionsQuery, TypeOptions, YupValidationFields} from "../types/global";
import {boolOptions, ISelect} from "../types/ISelect";

export const initialFilterValues: ClientFilter = {
  email: "",
  name: "",
  username: "",
  affiliate: "",
  telegramId: "",
  inviteLink: "",
  hideNullableMessageAt: null,
  status: null,
  progress: null,
  messageAt: [null, null],
};

export const getFilterFields = (): IAllFields<ClientFilter>[] => [
  {
    name: "email",
    type: TypesOfInput.TEXT,
  },
  {
    name: "name",
    type: TypesOfInput.TEXT,
  },
  {
    name: "username",
    type: TypesOfInput.TEXT,
  },
  {
    name: "affiliate",
    type: TypesOfInput.TEXT,
  },
  {
    name: "inviteLink",
    type: TypesOfInput.TEXT,
  },
  {
    name: "telegramId",
    type: TypesOfInput.TEXT,
  },
  {
    options: statusOptions,
    name: "status",
    type: TypesOfInput.SELECT,
  },
  {
    name: "hideNullableMessageAt",
    label: "Hide Message At",
    type: TypesOfInput.SELECT,
    options: boolOptions as ISelect<TypeOptions<boolean | null>>["options"],
  },
  {
    name: "progress",
    label: "Progress",
    type: TypesOfInput.SELECT,
    options: clientProgressOptions,
  },
  {
    label: "Message at",
    name: "messageAt",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
];

export const emptyInitialValues: ClientCreate = {
  name: "",
  email: "",
  status: null,
  telegramId: "",
  username: "",
  retention: null,
};

export const validationSchema = Yup.object<YupValidationFields<ClientCreate>>({
  name: Yup.string().nullable(),
  email: Yup.string().required("Required"),
  status: Yup.object().typeError("Required"),
  telegramId: Yup.string().required("Required"),
  username: Yup.string().nullable(),
  retention: Yup.object().nullable().typeError("Required"),
});

type Props = {
  retentionsQuery: OptionsQuery;
}

export const createFields = ({ retentionsQuery }: Props): IAllFields<ClientCreate>[] => [
  {
    name: "name",
    type: TypesOfInput.TEXT,
  },
  {
    name: "email",
    type: TypesOfInput.TEXT,
  },
  {
    name: "telegramId",
    type: TypesOfInput.TEXT,
  },
  {
    name: "username",
    type: TypesOfInput.TEXT,
  },
  {
    name: "status",
    type: TypesOfInput.SELECT,
    options: statusOptions,
  },
  {
    query: retentionsQuery,
    name: "retention",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "text"
  },
];
