import React, { useMemo, useEffect, useState } from "react";

import Table from "./tables/Table";
import Loading from "./Loading";
import { useLazyGetStatisticsTransactionsQuery } from "../api/statistics";
import { getColumnDefsStatisticsTransactions } from "../utils/statisticsColumn";
import { PaginationType } from "./tables/types";

const StatisticsTransactions = () => {
  const [pagination, setPagination] = useState<PaginationType>({ take: 10, page: 1 });
  const [fetch, query, { lastArg }] = useLazyGetStatisticsTransactionsQuery();
  const { data, isLoading } = query;

  useEffect(() => {
    if (data) {
      setPagination({
        ...data.pagination,
        page: data.pagination.currentPage || 1,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      lastArg.pagination?.page === pagination.page &&
      lastArg.pagination?.take === pagination.take
    ) {
      return;
    }

    fetch({
      pagination: { take: pagination.take, page: pagination.page },
    });
  }, [pagination]);

  const columns = useMemo(() => getColumnDefsStatisticsTransactions({ pagination }), [pagination]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="row">
      <section className="mb-5">
        <div className="card">
          <div className="card-body">
            <Table
              setPagination={setPagination}
              columns={columns}
              containerClass="overflow-auto"
              fixed
              items={data?.list}
              pagination={pagination}
              dataKey="statistics"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default StatisticsTransactions;
