import React, { useState, useMemo, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDeleteCommentMutation, useLazyGetClientCommentsQuery } from "../api/transactions";
import Icon from "./items/Icon";
import Loading from "./Loading";
import { formatDate, formatDateAndTime } from "../utils/formats";
import { useAppDispatch } from "../hooks/redux";
import CreateCommentModal from "./CreateCommentModal";

interface Props {
  clientId: string;
  commentsCount: number;
}

const CreateCommentAction = ({ clientId, commentsCount }: Props) => {
  const dispatch = useAppDispatch();
  const [deleteComment, { isLoading: isLoadingDelete }] = useDeleteCommentMutation();
  const [fetch, query] = useLazyGetClientCommentsQuery();
  const [showModal, setShowModal] = useState(false);
  const [showModalWithComments, setShowModalWithComments] = useState(false);

  const comments = useMemo(() => (query.isSuccess ? query.data.list : []), [query]);

  useEffect(() => {
    if (!showModalWithComments) return;
    fetch(clientId);
  }, [showModalWithComments]);

  const handleDelete = (id: string) => {
    deleteComment(id).unwrap().then(() => {
      dispatch({
        type: "clientsApi/invalidateTags",
        payload: ["clients"],
     });
    });
  };

  return (
    <>
      {!!commentsCount && (
        <Button
          className="text-xs"
          size="sm"
          variant="warning"
          onClick={() => setShowModalWithComments(true)}
        >
          <Icon name="comments" class="svg-icon-sm" />
        </Button>
      )}
      <Button className="text-xs" size="sm" variant="info" onClick={() => setShowModal(true)}>
        <Icon name="new-comment" class="svg-icon-sm" />
      </Button>
      <CreateCommentModal
        setShowModal={setShowModal}
        showModal={showModal}
        clientId={clientId}
        payload={["clients"]}
      />
      <Modal show={showModalWithComments} onHide={() => setShowModalWithComments(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {query.isLoading ? (
            <Loading />
          ) : (
            <Row>
              {comments.map((comment, index) => (
                <Col key={index} xs={12} md={6} lg={4} className="mt-3">
                  <div className="card">
                    <div className="card-body">
                      <p>Text: {comment.text}</p>
                      <p>Date: {formatDate(comment.createdAt)}</p>
                      <p>User: {comment?.user?.name}</p>
                      <p>User date: {formatDateAndTime(comment?.user?.createdAt)}</p>
                      <Button
                        className="w-auto mb-3"
                        variant="danger"
                        disabled={isLoadingDelete}
                        onClick={() => handleDelete(comment.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateCommentAction;
