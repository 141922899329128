import React, { useEffect, useState } from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { handleCopy } from "../api/utils";
import ColorLabel from "../components/items/ColorLabel";
import { Button, Modal } from "react-bootstrap";
import { statusOptions, Transaction } from "../models/Transaction";
import { JSONTree } from "react-json-tree";
import { useLazyGetTransactionQuery } from "../api/transactions";
import Icon from "../components/items/Icon";
import Loading from "../components/Loading";
import TransactionActions from "../components/TransactionActions";
import CreateCommentAction from "../components/CreateCommentAction";
import { Link } from "react-router-dom";

type IColumn = ColumnDef<Transaction>;

const testImage = "https://bitslog.files.wordpress.com/2013/01/unknown-person1.gif";

interface Props {
  handleSelectClient: (client: string) => void;
  pagination: PaginationType;
}

export default function getColumnDefsTransactions({
  handleSelectClient,
  pagination,
}: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return (
        <div>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - index * pagination?.currentPage}
        </div>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "60px",
    id: "№",
  };

  const code: IColumn = {
    Cell: ({ value }) => (
      <div className="text-truncate p-1 btn-link">
        <span onClick={() => handleCopy(value || "")} role="button">
          {value}
        </span>
      </div>
    ),
    Header: "Order",
    id: "code",
    accessor: "code",
  };

  const client: IColumn = {
    Cell: ({ value }) => (
      <div className="d-flex align-items-center justify-content-between">
        <Link className="d-flex align-items-center" to={`/clients/${value.id}`}>
          <img
            src={value.avatar || testImage}
            alt="avatar"
            className="rounded-circle mr-3 w-30px h-30px"
          />
          <div>
            <p className="text-base m-0">{value.name}</p>
            {value.username && <p className="text-xs m-0">Name: {value.username}</p>}
            {value.telegramId && <p className="text-xs m-0">TG id: {value.telegramId}</p>}
          </div>
        </Link>
        <div className="d-flex flex-column">
          <Button
            className="text-xs mb-1"
            size="sm"
            variant="success"
            onClick={() => handleSelectClient(value.id)}
          >
            <Icon name="filter-data-1" class="svg-icon-sm" />
          </Button>
          <CreateCommentAction clientId={value.id} commentsCount={value.commentsCount} />
        </div>
      </div>
    ),
    Header: "Client",
    id: "client",
    accessor: "client",
    width: "250px",
  };

  const currency: IColumn = {
    Cell: (cell) => (
      <>
        <div className="color-green">{cell.value.from}</div>
        <div className="color-orange">{cell.value.to}</div>
      </>
    ),
    Header: "Currency",
    id: "currency",
    accessor: "currency",
    width: "100px",
  };

  const address: IColumn = {
    Cell: ({ value }) => (
      <div className="text-truncate p-1 btn-link">
        <span onClick={() => handleCopy(value || "")} role="button">
          {value}
        </span>
      </div>
    ),
    Header: "Address",
    id: "address",
    accessor: "address",
  };

  const status: IColumn = {
    Cell: ({ value, row }) => (
      <div>
        <ColorLabel colors={statusOptions} value={value} label={value} />
        {value === "withdrawal_request" && row.original.sendAt && (
          <div>S {formatDateAndTime(row.original.sendAt)}</div>
        )}
      </div>
    ),
    Header: "Status",
    id: "status",
    accessor: "status",
    width: "150px",
  };

  const createdAt: IColumn = {
    Cell: ({ value, row }) => (
      <div>
        <div>D {row.original.depositedAt ? formatDateAndTime(row.original.depositedAt) : "-"}</div>
        <div>C {formatDateAndTime(value)}</div>
      </div>
    ),
    Header: "Dates",
    id: "Dates",
    accessor: "createdAt",
    width: "100px",
  };

  const balance: IColumn = {
    Cell: ({ value }) => (
      <span className={value && Number(value) > 0 ? "color-green" : "color-red"}>{value}</span>
    ),
    Header: "Balance",
    id: "balance",
    accessor: "balance",
    width: "100px",
  };

  const actions: IColumn = {
    Cell: (cell) => <TransactionActions cell={cell} />,
    Header: "Actions",
    id: "actions",
    accessor: "isAutoPay",
    width: "60px",
  };

  const logs: IColumn = {
    Cell: (cell) => {
      const [showModal, setShowModal] = useState(false);
      const [fetchTransaction, transactionInfo] = useLazyGetTransactionQuery();

      useEffect(() => {
        if (showModal) {
          fetchTransaction(cell.row.original.id);
        }
      }, [showModal]);

      return (
        <div>
          <Button
            size="sm"
            className="text-xs"
            onClick={() => setShowModal(true)}
            variant={cell.value ? "outline-danger" : "outline-primary"}
            disabled={!cell.value}
          >
            <Icon name="info" />
          </Button>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Logs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {transactionInfo.isLoading ? (
                <Loading />
              ) : (
                <JSONTree
                  data={transactionInfo.data?.model.logs}
                  theme={{ base00: "#fff" }}
                  invertTheme={false}
                  hideRoot={true}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    },
    Header: "Logs",
    id: "logsCount",
    accessor: "logsCount",
    width: "70px",
  };

  return [numberIndex, code, client, currency, address, status, createdAt, balance, logs, actions];
}
