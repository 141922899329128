import React, { useMemo } from "react";
import { useField } from "formik";
import Select, { MultiValue } from "react-select";
import { ISelect } from "../../../../types/ISelect";
import { FixMeLater, UnknownRecord } from "../../../../types/global";

const MultipleSelect = <T extends UnknownRecord>(props: ISelect<T>) => {
  const { name, valueName, options, formatOptions, isAllButton } = props;
  const [field, , helpers] = useField<MultiValue<string>>(name);
  const { setValue } = helpers;

  const formattedOptions = useMemo(() => {
    let result: FixMeLater[] = options;
    if (formatOptions) result = formatOptions(result);
    if (isAllButton) result = [{ value: 'all', label: 'All' }, ...result];    
    return result;
  }, [options, formatOptions, isAllButton]);

  const findValue = () => {
    if (!field.value) return [];
    return formattedOptions.filter((option) => field.value.includes(option?.[valueName] as string));
  };

  return (
    <Select
      {...field}
      {...props}
      closeMenuOnSelect={false}
      isMulti
      value={findValue()}
      onChange={(selectedValue: MultiValue<UnknownRecord>) => {
        if (!selectedValue.length) return setValue([]);
        if (isAllButton && selectedValue.find((option) => option.value === "all")) {
          return setValue(options.slice(1).map((item) => item[valueName]));
        }
        setValue(selectedValue.map((item) => item[valueName] as string));
      }}
    />
  );
};

export default MultipleSelect;
