import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { Site } from "../models/Site";
import { Link } from "react-router-dom";
import Label from "../components/items/Label";
import { baseGetImageURL } from "../api/utils";

type IColumn = ColumnDef<Site>;

interface Props {
  pagination: PaginationType;
}

export default function getColumnDefsSites({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row, value }) => {
      return (
        <Link to={`/sites/${value}`}>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - row.index * pagination?.currentPage}
        </Link>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    width: "20px",
    id: "№",
  };

  const logo: IColumn = {
    Cell: ({ value }) => (
      <img className="svg-icon-lg of-contain" src={`${baseGetImageURL}/${value}`} alt="qr" />
    ),
    Header: "Logo",
    id: "logo",
    accessor: "logo",
    width: "50px",
  };

  const name: IColumn = {
    Header: "Name",
    id: "name",
    accessor: "name",
    width: "50px",
  };

  const support: IColumn = {
    Header: "Support",
    id: "support",
    accessor: "support",
    width: "100px",
  };

  const domain: IColumn = {
    Header: "Domain",
    id: "domain",
    accessor: "domain",
    width: "50px",
  };

  const status: IColumn = {
    Cell: ({ value }) => <Label status={Boolean(value)} />,
    Header: "Status",
    id: "status",
    accessor: "status",
    width: "50px",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "80px",
  };

  return [numberIndex, name, logo, domain, support, status, createdAt];
}
