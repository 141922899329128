import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { CellProps } from "react-table";
import * as Yup from "yup";
import {
  useChangeStatusMutation,
  useChangeStatusToDepositedMutation,
  useLazyGetTransactionQuery,
  useResendEmailMutation,
  useUpdateTransactionMutation,
} from "../api/transactions";
import {
  Transaction,
  UpdateTransactionData,
  UpdateTransactionStatuses,
} from "../models/Transaction";
import { TypesOfInput } from "../types/IField";
import { JSONTree } from "react-json-tree";
import Icon from "./items/Icon";
import Loading from "./Loading";
import Field from "./form/formik/Field";
import { useAppSelector } from "../hooks/redux";

interface Props {
  cell: CellProps<Transaction, 0 | 1>;
}

const TransactionActions = ({ cell }: Props) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [showPaymentHash, setShowPaymentHash] = useState(false);

  const { auth } = useAppSelector((state) => state.authSlice);

  const [resendEmail, { isLoading: isLoadingResendEmail }] = useResendEmailMutation();
  const [changeStatus, { isLoading: isLoadingChangeStatus }] = useChangeStatusMutation();
  const [updateTransaction, { isLoading: isLoadingUpdate }] = useUpdateTransactionMutation();
  const [changeStatusToDeposited, { isLoading: isLoadingChangeStatusToDeposited }] = useChangeStatusToDepositedMutation();
  const [fetchTransaction, transactionInfo] = useLazyGetTransactionQuery();

  const transactionId = useMemo(() => cell.row.original.id, [cell]);
  const role = useMemo(() => auth.user?.role, [auth]);

  const transaction = useMemo(
    () => (transactionInfo.isSuccess ? transactionInfo.data : null),
    [transactionInfo.isSuccess, transactionInfo.data],
  );

  useEffect(() => {
    if (!showInfoModal && !showChangeModal) {
      return;
    }
    fetchTransaction(transactionId);
  }, [showInfoModal, showChangeModal]);

  const handleChange = (data: UpdateTransactionData) => {
    updateTransaction({
      id: transactionId,
      data,
    }).then(() => {
      setShowChangeModal(false);
    });
  };

  const handleChangeStatus = (status: UpdateTransactionStatuses) => {
    updateTransaction({
      id: transactionId,
      data: { status },
    });
  };

  const handleWithdrawalTransaction = () => {
    changeStatus({
      id: cell.row.original.id,
      status: "withdrawal",
    });
  };

  const handlePaymentHash = (data: UpdateTransactionData) => {
    updateTransaction({
      id: transactionId,
      data,
    }).then(() => {
      setShowPaymentHash(false);
    });
  };

  const isShowButtons = cell.row.original.status === "deposited";

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="grid gap-3">
        {cell.row.original.status === "approved" && (
          <Button
            className="text-uppercase"
            onClick={() => changeStatusToDeposited(transactionId)}
            variant="success"
            disabled={isLoadingChangeStatusToDeposited}
          >
            change status to deposited
          </Button>
        )}
        {(role === "manager" || role === "admin") && (
          <>
            <Button
              className="text-uppercase"
              onClick={() => setShowInfoModal(true)}
              variant="success"
              disabled={showInfoModal}
            >
              show
            </Button>
            <Button
              className="text-uppercase"
              onClick={() => resendEmail(transactionId)}
              variant="primary"
              disabled={isLoadingResendEmail}
            >
              resend email
            </Button>
            {isShowButtons && (
              <>
                <Button
                  className="text-uppercase"
                  onClick={() => setShowChangeModal(true)}
                  variant="info"
                  disabled={showChangeModal}
                >
                  change
                </Button>
                <Button
                  className="text-uppercase"
                  onClick={() => handleChangeStatus("withdrawal_request")}
                  variant="primary"
                  disabled={isLoadingUpdate}
                >
                  to withdrawal
                </Button>
                <Button
                  className="text-uppercase"
                  onClick={() => handleChangeStatus("retention")}
                  variant="warning"
                  disabled={isLoadingUpdate}
                >
                  retention
                </Button>
              </>
            )}
          </>
        )}
        {(role === "payer" || role === "admin") && (
          <>
            <Button
              className="text-uppercase"
              onClick={() => setShowPaymentHash(true)}
              variant="success"
              disabled={isLoadingChangeStatus}
            >
              payment hash
            </Button>
            <Button
              className="text-uppercase"
              onClick={handleWithdrawalTransaction}
              variant="info"
              disabled={isLoadingChangeStatus}
            >
              withdrawal
            </Button>
          </>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
        <Button size="sm" className="text-xs" variant="outline-primary">
          <Icon name="menu-1" />
        </Button>
      </OverlayTrigger>

      <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Order Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!transaction ? (
            <Loading />
          ) : (
            <JSONTree
              data={transaction.model}
              theme={{ base00: "#fff" }}
              invertTheme={false}
              hideRoot={true}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showChangeModal} onHide={() => setShowChangeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!transaction ? (
            <Loading />
          ) : (
            <Formik
              enableReinitialize
              initialValues={{
                hash: transaction?.model.hash || "",
                percent: transaction?.model.percent || 1,
                sendAt: null,
              }}
              validationSchema={Yup.object().shape({
                hash: Yup.string().nullable(),
                percent: Yup.number().min(-7).max(7).required("Required"),
                sendAt: Yup.string().nullable()
              })}
              onSubmit={handleChange}
            >
              <Form>
                <Field type={TypesOfInput.NUMBER} name="percent" size={12} />
                <Field type={TypesOfInput.TEXT} name="hash" size={12} />
                <Field type={TypesOfInput.DATEPICKER} showTime name="sendAt" size={12} />
                <Button
                  disabled={isLoadingUpdate}
                  type="submit"
                  className="w-100"
                  variant="success"
                >
                  Save
                </Button>
              </Form>
            </Formik>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showPaymentHash} onHide={() => setShowPaymentHash(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment hash</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              paymentHash: "",
              status: "withdrawal"
            }}
            validationSchema={Yup.object().shape({
              paymentHash: Yup.string().required("Required"),
            })}
            onSubmit={handlePaymentHash}
          >
            <Form>
              <Field type={TypesOfInput.TEXT} name="paymentHash" size={12} />
              <Button disabled={isLoadingUpdate} type="submit" className="w-100" variant="success">
                Save
              </Button>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionActions;
