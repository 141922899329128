import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import {Client, statusOptions} from "../models/Client";
import ColorLabel from "../components/items/ColorLabel";
import { Link } from "react-router-dom";
import CreateRequestForClient from "../components/CreateRequestForClient";
import EditClientModal from "../components/EditClientModal";
import ClientHistoryModal from "../components/ClientHistoryModal";
import CreateCommentAction from "../components/CreateCommentAction";

type IColumn = ColumnDef<Client>;
const testImage = "https://bitslog.files.wordpress.com/2013/01/unknown-person1.gif";

interface Props {
  pagination: PaginationType;
}

export default function getColumnDefsClients({
  pagination
}: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return (
        <div>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - index * pagination?.currentPage}
        </div>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "50px",
    id: "№",
  };

  const client: IColumn = {
    Cell: ({ cell }) => (
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <img
            src={cell.row.original.avatar || testImage}
            alt="avatar"
            className="rounded-circle mr-3 w-30px h-30px"
          />
          <Link to={`/clients/${cell.row.original.id}`}>
            <p className="text-base">{cell.value}</p>
            <p className="text-xs">
              {cell.row.original.username}/{cell.row.original.telegramId}
            </p>
          </Link>
        </div>
      </div>
    ),
    Header: "Client",
    id: "name",
    accessor: "name",
    width: "200px",
  };

  const statusInfo: IColumn = {
    Cell: ({ value }) => <span>{value?.name}</span>,
    Header: "Status info",
    id: "statusInfo",
    accessor: "statusInfo",
    width: "auto",
  };

  const balance: IColumn = {
    Header: "Balance",
    id: "balance",
    accessor: "balance",
    width: "auto",
  };

  const actions: IColumn = {
    Cell: ({ cell }) => (
      <>
        <CreateRequestForClient clienId={cell.value} />
        <EditClientModal clienId={cell.value} values={cell.row.original} />
      </>
    ),
    Header: "Actions",
    id: "actions",
    width: "auto",
    accessor: "id",
  };

  const email: IColumn = {
    Header: "E-mail",
    id: "email",
    accessor: "email",
    width: "auto",
  };

  const source: IColumn = {
    Header: "Source",
    id: "source",
    accessor: "source",
    width: "auto",
  };

  const affiliate: IColumn = {
    Header: "Affiliate",
    id: "affiliate",
    accessor: "affiliate",
    width: "auto",
  };

  const messageAt: IColumn = {
    Cell: ({ value }) => <span>{value ? formatDateAndTime(value) : ""}</span>,
    Header: "Message",
    id: "Message",
    accessor: "messageAt",
    width: "auto",
  };

  const status: IColumn = {
    Cell: ({ value }) => <ColorLabel colors={statusOptions} value={value} label={value} />,
    Header: "Status",
    id: "status",
    accessor: "status",
    width: "75px"
  };

  const progress: IColumn = {
    Cell: ({ value, row }) => <ClientHistoryModal progress={value} clienId={row.original.id} />,
    Header: "Progress",
    id: "progress",
    accessor: "progress",
    width: "100px"
  };

  const nationality: IColumn = {
    Header: "Nationality",
    id: "nationality",
    accessor: "nationality",
    width: "75px"
  };

  const geo: IColumn = {
    Header: "Geo",
    id: "geo",
    accessor: "geo",
    width: "auto"
  };

  const age: IColumn = {
    Header: "Age",
    id: "age",
    accessor: "age",
    width: "auto"
  };

  const comments: IColumn = {
    Cell: ({ row }) => (
      <CreateCommentAction clientId={row.original.id} commentsCount={row.original.commentsCount} />
    ),
    Header: "Comments",
    id: "comments",
    accessor: "id",
    width: "100px"
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "auto",
  };

  return [numberIndex, client, actions, email, balance, source, statusInfo, affiliate, progress, comments, nationality, geo, age, messageAt, status, createdAt];
}
