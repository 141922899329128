import React, { useEffect, useState, useMemo } from "react";
import { useField } from "formik";
import Select, { SingleValue } from "react-select";
import useDebounce from "../../../../hooks/useDebounce";
import { ISelect } from "../../../../types/ISelect";
import { FixMeLater, PAGINATION, UnknownRecord, defaultOrderByFilter } from "../../../../types/global";

const AsyncSelect = <T extends UnknownRecord>(props: ISelect<T>) => {
  const { query, name, formatOptions, withoutPagination } = props;
  const [field, meta, helpers] = useField<UnknownRecord | null>(name);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce<string>(search, 500);
  const [wasOpened, setWasOpened] = useState(false);
  const queryData = query({
    pagination: withoutPagination ? null : PAGINATION,
    searchTerm: debouncedSearch,
    orderBy: defaultOrderByFilter,
  });

  const options = useMemo(
    () =>
      queryData.data
        ? formatOptions
          ? formatOptions(queryData.data?.list)
          : queryData.data?.list || []
        : [],
    [queryData.data, formatOptions],
  );

  useEffect(() => {
    if (!debouncedSearch && !wasOpened) return;
    queryData.refetch();
  }, [debouncedSearch, wasOpened]);

  const { setValue } = helpers;

  const handleFetchOptions = () => {
    setWasOpened(true);
    if (!!queryData && !wasOpened) {
      queryData.refetch();
    }
  };

  const handleFilter = () => true;

  return (
    <Select
      {...field}
      {...props}
      value={meta.value as FixMeLater}
      isMulti={false}
      onChange={(option: SingleValue<UnknownRecord>) => {
        setValue(option);
      }}
      onMenuOpen={handleFetchOptions}
      onInputChange={setSearch}
      isLoading={queryData.isLoading && wasOpened}
      options={options}
      filterOption={handleFilter}
    />
  );
};

export default AsyncSelect;
