import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys, decamelizeKeys } from "humps";

import { PaginationQuery } from "../components/tables/types";
import { User } from "../models/User";
import { ListRes, filterEmpyValues, getBaseQuery, getBaseUrl } from "./utils";

const url = `${getBaseUrl()}/users`;
const tagType = "users";

export const usersApi = createApi({
  reducerPath: "usersApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getUsers: build.query<
      ListRes<User>,
      { pagination?: PaginationQuery, filter?: unknown, searchTerm?: string }
    >({
      query: (body) => {
        const data = {
          ...body.pagination,
          filter: filterEmpyValues(body.filter || {}),
        };

        return {
          url: "list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<User>) =>
        camelizeKeys(response) as ListRes<User>,
    }),
  }),
});

export const {
  useGetUsersQuery,
} = usersApi;
