import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { Invitation } from "../models/Invitation";
import { handleCopy } from "../api/utils";

type IColumn = ColumnDef<Invitation>;

interface Props {
  pagination: PaginationType;
}

export default function ({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row }) => {
      return (
        <span>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - row.index * pagination?.currentPage}
        </span>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    width: "20px",
    id: "№",
  };

  const link: IColumn = {
    Cell: ({ value }) => (
      <div className="text-truncate p-1 btn-link">
        <span onClick={() => handleCopy(value || "")} role="button">
          {value ? value.split("t.me/+")[1] : "No"}
        </span>
      </div>
    ),
    Header: "Link",
    id: "link",
    accessor: "link",
  };

  const deepLink: IColumn = {
    Cell: ({ value }) => (
      <div className="text-truncate p-1 btn-link">
        <span onClick={() => handleCopy(value || "")} role="button">
          {value ? value.split("invite=")[1] : "No"}
        </span>
      </div>
    ),
    Header: "Deep link",
    id: "deepLink",
    accessor: "deepLink",
  };

  const user: IColumn = {
    Cell: ({ value }) => (<span>{value?.affiliate}</span>),
    Header: "User",
    id: "user",
    accessor: "user",
  };

  const source: IColumn = {
    Header: "Source",
    id: "source",
    accessor: "source",
  };

  const channel: IColumn = {
    Header: "Channel",
    id: "channel",
    accessor: "channelName",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
  };

  return [numberIndex, deepLink, user, link, source, channel, createdAt];
}
