import { LabelColors, ListItem, TypeOptions } from "../types/global";
import { OptionModel } from "../types/ISelect";
import { Client } from "./Client";
import { Currency } from "./Currency";

export type PairType = "basic" | "addition";

export type PairGeneral = {
  domain: string,
  percent: number,
  isSelectedPair?: boolean,
  available: null | number,
  max: null | number,
  min: null | number,
}

export type CreatePairType = PairGeneral & {
  client: OptionModel<Client>,
  currencyFrom: OptionModel<Currency>,
  currencyTo: OptionModel<Currency>,
  type: OptionModel<TypeOptions<PairType>>
}

export type CreatePairTypeRequest = PairGeneral & {
  clientId: OptionModel<string>,
  currencyFromId: OptionModel<string>,
  currencyToId: OptionModel<string>,
  type: OptionModel<PairType>
  isSelected: boolean,
}

export type UpdatePairTypeRequest = CreatePairTypeRequest & {
  id: string,
}

export type PairModel = PairGeneral & ListItem<{
  client: null | Client,
  currency: string,
  currencyFrom: Currency,
  currencyTo: Currency,
  type: PairType,

}>

export type Pair = ListItem<PairGeneral & {
  currency: string,
  min: number,
  max: number,
  client: null | Client,
  type: PairType,
}>

export type PairFilter = {
  client: OptionModel<Client>,
  domain: string,
  currencyFrom: OptionModel<Currency>,
  currencyTo: OptionModel<Currency>,
  type: OptionModel<TypeOptions<PairType>>,
}

export type PairFilterRequest = {
  clientId: string,
  domain: string,
  currencyFromId: string,
  currencyToId: string,
  type: string,
}

export const typeOptions: TypeOptions<PairType>[] = [
  {
    color: LabelColors.BLUE,
    label: "basic",
    value: "basic",
  },
  {
    color: LabelColors.BLUE_LIGHT,
    label: "addition",
    value: "addition",
  }
];

export type ClonePairs = {
  ids: string[],
  domain: string,
}
