import { camelizeKeys, decamelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { getBaseFormQuery, getBaseUrl, ListRes } from "./utils";
import { Pixel, PixelCreate, PixelCreateRequest, PixelFilter } from "../models/Pixel";

const url = `${getBaseUrl()}/pixels`;
const tagType = "pixels";

export const pixelsApi = createApi({
  reducerPath: "pixelsApi",
  tagTypes: [tagType],
  baseQuery: getBaseFormQuery(url),
  endpoints: (build) => ({
    getPixels: build.query<
      ListRes<Pixel>,
      { pagination: PaginationQuery, filter?: PixelFilter, searchTerm?: string }
    >({
      query: ({ searchTerm }) => ({
        url: "list",
        method: "POST",
        body: decamelizeKeys({ searchTerm }),
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Pixel>) =>
        camelizeKeys(response) as ListRes<Pixel>,
    }),
    createItem: build.mutation<void, PixelCreate>({
      query(data) {
        const sendingData: PixelCreateRequest = {
          ...data,
          source: data.source?.value || "google",
        };
        return {
          url: "",
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useGetPixelsQuery,
  useLazyGetPixelsQuery,
  useCreateItemMutation,
} = pixelsApi;
