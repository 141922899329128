import React from "react";
import { UnknownRecord } from "../../../../types/global";
import { ISelect } from "../../../../types/ISelect";
import ErrorText from "../../ErrorText";
import AsyncMultipleSelect from "./AsyncMultipleSelect";
import AsyncSelect from "./AsyncSelect";
import CustomSelect from "./CustomSelect";
import MultipleSelect from "./MultipleSelect";
import SingleSelect from "./SingleSelect";

const Select = <T extends UnknownRecord>(props: ISelect<T>) => {
  const labelName = props.labelName || "label";
  const valueName = props.valueName || "value";

  const options = props.options || [];

  const defaultProps = {
    ...props,
    getOptionLabel: (item: T) => item[labelName],
    getOptionValue: (item: T) => item[valueName],
    isClearable: true,
    id: props.name,
    options,
    labelName,
    valueName,
  };

  const renderSelect = () => {
    /* eslint-disable-next-line no-extra-boolean-cast */
    if (Boolean(props.query)) {
      if (props.isMulti) {
        return <AsyncMultipleSelect {...props} {...defaultProps} />;
      } else {
        return <AsyncSelect {...props} {...defaultProps} />;
      }
    } else if (props.isCustomValue) {
      return <CustomSelect {...props} {...defaultProps} />;
    } else if (props.isMulti) {
      return <MultipleSelect {...props} {...defaultProps} />;
    } else {
      return <SingleSelect {...props} {...defaultProps} />;
    }
  };

  return (
    <div>
      {props.label && (
        <label htmlFor={props.name} className="form-label-test">
          {props.label}
        </label>
      )}
      {renderSelect()}
      <ErrorText name={props.name} />
    </div>
  );
};

export default Select;
