import * as Yup from "yup";
import { CreatePairType, PairFilter, PairType, typeOptions } from "../models/Pair";
import { OptionsQuery, TypeOptions, YupValidationFields } from "../types/global";
import { IAllFields, TypesOfInput } from "../types/IField";
import { OptionModel } from "../types/ISelect";

export const initialValues: CreatePairType = {
  domain: "",
  percent: 1,
  type: typeOptions[0],
  isSelectedPair: false,
  available: 10,
  client: null,
  currencyFrom: null,
  currencyTo: null,
  min: 1,
  max: 100,
};

export const filterInitialValues: PairFilter = {
  currencyFrom: null,
  currencyTo: null,
  client: null,
  domain: "",
  type: typeOptions.find(item => item.value === "basic") as OptionModel<TypeOptions<PairType>>,
};

export const validationSchema = Yup.object<YupValidationFields<CreatePairType>>({
  domain: Yup.string().required("Required"),
  percent: Yup.number().required("Required"),
  available: Yup.number().required("Required"),
  min: Yup.number(),
  max: Yup.number(),
  isSelectedPair: Yup.boolean().required("Required"),
  type: Yup.object().typeError("Required"),
  currencyTo: Yup.object().typeError("Required"),
  currencyFrom: Yup.object().typeError("Required"),
  client: Yup.object().nullable(),
});

export const getFilterFields = ({
  currenciesQuery,
  clientsQuery,
}: Props): IAllFields<PairFilter>[] => [
    {
      name: "domain",
      type: TypesOfInput.TEXT,
    },
    {
      query: currenciesQuery,
      name: "currencyFrom",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "name"
    },
    {
      query: currenciesQuery,
      name: "currencyTo",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "name"
    },
    {
      query: clientsQuery,
      name: "client",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "telegramId"
    },
    {
      name: "type",
      options: typeOptions,
      type: TypesOfInput.SELECT,
    },
  ];

type Props = {
  clientsQuery: OptionsQuery;
  currenciesQuery: OptionsQuery;
}

export const createFields = ({
  currenciesQuery,
  clientsQuery,
}: Props): IAllFields<CreatePairType>[] => [
    {
      name: "domain",
      type: TypesOfInput.TEXT,
    },
    {
      query: currenciesQuery,
      name: "currencyFrom",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "name"
    },
    {
      query: currenciesQuery,
      name: "currencyTo",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "name"
    },
    {
      query: clientsQuery,
      name: "client",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "telegramId"
    },
    {
      name: "type",
      options: typeOptions,
      type: TypesOfInput.SELECT,
    },
    {
      name: "percent",
      type: TypesOfInput.TEXT,
    },
    {
      name: "available",
      type: TypesOfInput.TEXT,
    },
    {
      name: "min",
      type: TypesOfInput.TEXT,
    },
    {
      name: "max",
      type: TypesOfInput.TEXT,
    },
    {
      name: "isSelectedPair",
      type: TypesOfInput.CHECKBOX,
    },
  ];
