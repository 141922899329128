import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { Column, usePagination, useSortBy, useTable, useRowSelect } from "react-table";
import HeadCell from "./HeadCell";
import { PaginationType, SortableColumnInstance } from "./types";
import { UnknownRecord } from "../../types/global";
import Pagination from "./Pagination";
import { Button } from "react-bootstrap";
import Icon from "../items/Icon";
import TableSettings from "./TableSettings";
import { getCookies, setCookies } from "../../api/utils";
import IndeterminateCheckbox from "../form/IndeterminateCheckbox";
import Loading from "../Loading";

interface Props<T extends UnknownRecord> {
  className?: string;
  dataKey: string;
  columns: Column<T>[];
  columnSortFlags?: boolean[];
  containerClass?: string;
  fixed?: boolean;
  rowSelect?: boolean;
  max?: boolean;
  items?: T[];
  pagination?: PaginationType;
  handleSelect?: (ids: string[]) => void;
  setPagination?: React.Dispatch<React.SetStateAction<PaginationType>>;
}

const Table = <T extends UnknownRecord>(props: Props<T>) => {
  const {
    columnSortFlags,
    columns,
    containerClass,
    items = [],
    pagination,
    dataKey = "",
    rowSelect,
    handleSelect,
    setPagination,
  } = props;
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const tableSettings: { [key: string]: string[] } = getCookies("tableSettings") || {};
  const hiddenColumnsName: string[] = tableSettings[dataKey] || [];
  useEffect(() => {
    setHiddenColumns(hiddenColumnsName);
  }, []);

  const handleSaveHiddenColumns = (names: string[]) => {
    setHiddenColumns(names);
    setCookies("tableSettings", {
      ...tableSettings,
      [dataKey]: names,
    });
  };

  const table = useTable(
    {
      columns,
      data: items,
      initialState: {
        pageSize: pagination?.take || 100,
        hiddenColumns: dataKey ? hiddenColumnsName : [],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (rowSelect) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            width: 20,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...(getToggleAllRowsSelectedProps() as any)} />
            ),
            Cell: ({ row }: any) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ]);
      }
    },
  );
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    setHiddenColumns,
    selectedFlatRows,
  } = table;

  useEffect(() => {
    if (rowSelect && handleSelect) {
      handleSelect(selectedFlatRows.map((item) => item.original.id) as string[]);
    }
  }, [selectedFlatRows]);

  const className = cn("Table", props.className, {
    "Table--fixed": props.fixed,
    "Table--w-max": props.max,
    "Table--sortable": columnSortFlags,
  });

  const handleChangePageSize = (take: number) => {
    setPageSize(take);
    if (!setPagination) return;
    setPagination((prevState) => ({ ...prevState, take }));
  };

  const onChangePerPage = (page: number) => {
    if (!setPagination) return;
    setPagination((prevState) => ({ ...prevState, page }));
  };

  const columnsName: string[] = useMemo(() => {
    const names: string[] = [];
    columns.forEach((item) => item.id && names.push(item.id));
    return names;
  }, []);

  return (
    <>
      <div className={containerClass}>
        <table className={className} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeadCell
                    column={column}
                    key={column.id}
                    sortable={columnSortFlags ? columnSortFlags[index] : columnSortFlags}
                  />
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="Table__row">
                  {row.cells.map((cell) => {
                    const { alignRight, dataCellClass } = cell.column as SortableColumnInstance<T>;
                    const className = cn("Table-cell", dataCellClass, {
                      "text-right": alignRight,
                    });
                    return (
                      <td className={className} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pagination && (
        <div className="dataTable-bottom flex flex-wrap w-full justify-between items-center mt-3">
          <Pagination
            pagination={pagination}
            changePage={onChangePerPage}
            changePageSize={handleChangePageSize}
          />
          <Button variant="primary" onClick={() => setIsOpenSettings(true)}>
            <Icon name="settings-2" class="svg-icon-md" />
          </Button>
        </div>
      )}
      {dataKey && (
        <TableSettings
          show={isOpenSettings}
          onClose={() => setIsOpenSettings(false)}
          columnsName={columnsName}
          hiddenColumnsName={hiddenColumnsName}
          handleSaveHiddenColumns={handleSaveHiddenColumns}
        />
      )}
    </>
  );
};

export default Table;
