import { FakeTransactionForm } from "../models/FakeTransaction";
import { statusOptions } from "../models/Transaction";
import { OptionsQuery } from "../types/global";
import { IAllFields, TypesOfInput } from "../types/IField";

export const initialValues: FakeTransactionForm = {
  code: "",
  address: "",
  currency: "",
  amount: "",
  status: null,
  createdAt: "",
  retentionText: "",
  retentionComment: "",
  retentionEnum: null,
};

type Props = {
  retentionsQuery: OptionsQuery;
}

export const getCreateFields = ({ retentionsQuery }: Props): IAllFields<FakeTransactionForm>[] => [
  {
    name: "code",
    label: "Заказ",
    type: TypesOfInput.TEXT,
  },
  {
    name: "address",
    label: "Адрес",
    type: TypesOfInput.TEXT,
  },
  {
    name: "currency",
    label: "Пара",
    type: TypesOfInput.TEXT,
  },
  {
    name: "amount",
    label: "Сумма",
    type: TypesOfInput.TEXT,
  },
  {
    name: "status",
    label: "Статус",
    type: TypesOfInput.SELECT,
    options: statusOptions
  },
  {
    name: "createdAt",
    label: "Создано",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Информация (заголовок)",
    name: "retentionText",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Информация (описание)",
    name: "retentionComment",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Выбрать ретен",
    query: retentionsQuery,
    name: "retentionEnum",
    type: TypesOfInput.SELECT,
    withoutPagination: true,
    labelName: "text",
    valueName: "id",
  },
];
