import * as Yup from "yup";
import { IAllFields, IField, TypesOfInput } from "../types/IField";
import { WalletCreate, WalletFilter, WithdrawalWalletType, typeOptions } from "../models/Wallet";
import { OptionsQuery, TypeOptions, YupValidationFields } from "../types/global";
import { ISelect, boolOptions } from "../types/ISelect";

export const emptyInitialValues: WalletCreate = {
  address: "",
  domain: null,
  group: null,
  currency: null,
  status: true,
  type: typeOptions[0],
  onCheck: false,
};

export const emptyFilterValues: WalletFilter = {
  address: "",
  domain: null,
  group: null,
  currency: null,
  status: null,
  hideNullableBalance: null,
  onCheck: null,
  type: null,
};

export const validationSchema = Yup.object<YupValidationFields<WalletCreate>>({
  address: Yup.string().required("Required"),
  currency: Yup.object().typeError("Required"),
});

type Props = {
  currenciesQuery: OptionsQuery;
}

export const getCreateFields = ({ currenciesQuery }: Props): IAllFields<WalletCreate>[] => [
  {
    name: "group",
    type: TypesOfInput.TEXT,
  },
  {
    name: "domain",
    type: TypesOfInput.TEXT,
  },
  {
    name: "address",
    type: TypesOfInput.TEXT,
  },
  {
    query: currenciesQuery,
    name: "currency",
    type: TypesOfInput.SELECT,
    labelName: "name"
  },
  {
    name: "status",
    type: TypesOfInput.CHECKBOX,
    labelName: "status"
  },
  {
    name: "onCheck",
    type: TypesOfInput.CHECKBOX,
    labelName: "On check"
  },
  {
    options: typeOptions,
    name: "type",
    type: TypesOfInput.SELECT,
  },
];

export const getFilterFields = ({ currenciesQuery }: Props): IAllFields<WalletFilter>[] => [
  {
    name: "address",
    type: TypesOfInput.TEXT,
  },
  {
    query: currenciesQuery,
    name: "currency",
    type: TypesOfInput.SELECT,
    labelName: "name"
  },
  {
    name: "type",
    type: TypesOfInput.SELECT,
    options: typeOptions,
  },
  {
    name: "status",
    type: TypesOfInput.SELECT,
    options: boolOptions as ISelect<TypeOptions<boolean | null>>["options"],
  },
  {
    name: "onCheck",
    type: TypesOfInput.SELECT,
    options: boolOptions as ISelect<TypeOptions<boolean | null>>["options"],
  },
  {
    name: "hideNullableBalance",
    label: "Hide nullable balance",
    type: TypesOfInput.SELECT,
    options: boolOptions as ISelect<TypeOptions<boolean | null>>["options"],
  },
];

export const getWithdrawalFields = (): IField<WithdrawalWalletType>[] => [
  {
    name: "address",
    type: TypesOfInput.TEXT,
  },
  {
    name: "blockchain",
    type: TypesOfInput.TEXT,
  },
  {
    name: "token",
    type: TypesOfInput.TEXT,
  },
  {
    name: "memo",
    type: TypesOfInput.TEXT,
  },
];

export const emptyWithdrawalWallet: WithdrawalWalletType = {
  address: "",
  blockchain: "",
  token: "",
  memo: "",
};

export const withdrawalWalletvalidationSchema = Yup.object<YupValidationFields<WithdrawalWalletType>>({
  address: Yup.string().required("Required"),
  blockchain: Yup.string().required("Required"),
  token: Yup.string().required("Required"),
  memo: Yup.string(),
});
