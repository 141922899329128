import React from "react";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { Statistic, StatisticClients, StatisticTransactions } from "../models/Statistic";
import { formatDate, formatDateAndTime } from "./formats";
import { Link } from "react-router-dom";

type IColumnClients = ColumnDef<StatisticClients>;
type IColumnTransactions = ColumnDef<StatisticTransactions>;

export const getAmountFromString = (value: string): [number, number] => {
  const regExp = /\(([^)]+)\)/;
  const valueInBrackets = regExp.exec(value)?.[1] || 0;
  const valueWithoutBrackets = value.split(" ")[0] || 0;
  return [Number(valueWithoutBrackets), Number(valueInBrackets)];
};

export function getColumnDefsStatisticsClients(): IColumnClients[] {
  const affiliate: IColumnClients = {
    Header: "Affiliate",
    id: "affiliate",
    accessor: "affiliate",
    width: "50px",
  };

  const clients: IColumnClients = {
    Header: "Clients",
    id: "clients",
    accessor: "clients",
    width: "50px",
  };

  const messages: IColumnClients = {
    Header: "Messages",
    id: "messages",
    accessor: "messages",
    width: "50px",
  };

  const visited: IColumnClients = {
    Header: "Visited",
    id: "visited",
    accessor: "visited",
    width: "50px",
  };

  const transactions: IColumnClients = {
    Header: "Transactions",
    id: "transactions",
    accessor: "transactions",
    width: "50px",
  };

  const retentions: IColumnClients = {
    Header: "Deposits",
    id: "retentions",
    accessor: "retentions",
    width: "50px",
  };

  return [affiliate, clients, messages, visited, transactions, retentions];
}

type IColumn = ColumnDef<Statistic>;

interface Props {
  pagination: PaginationType;
}

export function getColumnDefsStatistics({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row, value }) => {
      return (
        <Link to={`/wallets/${value}`}>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - row.index * pagination?.currentPage}
        </Link>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    id: "№",
  };

  const affiliate: IColumn = {
    Header: "Affiliate",
    id: "affiliate",
    accessor: "affiliate",
  };

  const source: IColumn = {
    Header: "Source",
    id: "source",
    accessor: "source",
  };

  const channel: IColumn = {
    Header: "Channel",
    id: "channel",
    accessor: "channel",
  };

  const usedAt: IColumn = {
    Header: "Used at",
    Cell: ({ value }) => <div>{formatDateAndTime(value)}</div>,
    id: "usedAt",
    accessor: "usedAt",
  };

  const messageAt: IColumn = {
    Header: "Message at",
    Cell: ({ value }) => <div>{value ? formatDateAndTime(value) : ""}</div>,
    id: "messageAt",
    accessor: "messageAt",
  };

  const client: IColumn = {
    Header: "Client",
    Cell: ({ value }) =>
      value && (
        <div>
          <div>{value.name}</div>
          <div>{value.telegramId}</div>
          <div>{formatDate(value.createdAt)}</div>
        </div>
      ),
    id: "client",
    accessor: "client",
  };

  const sub1: IColumn = {
    Header: "Sub1",
    id: "Sub1",
    accessor: "sub1",
  };

  const sub2: IColumn = {
    Header: "Sub2",
    id: "sub2",
    accessor: "sub2",
  };

  const sub3: IColumn = {
    Header: "Sub3",
    id: "sub3",
    accessor: "sub3",
  };

  const sub4: IColumn = {
    Header: "Sub4",
    id: "sub4",
    accessor: "sub4",
  };

  const sub5: IColumn = {
    Header: "Sub5",
    id: "sub5",
    accessor: "sub5",
  };

  return [
    numberIndex,
    client,
    source,
    affiliate,
    messageAt,
    channel,
    sub1,
    sub2,
    sub3,
    sub4,
    sub5,
    usedAt,
  ];
}

export function getColumnDefsStatisticsTransactions({ pagination }: Props): IColumnTransactions[] {
  const numberIndex: IColumnTransactions = {
    Cell: ({ row }) => {
      return (
        <span>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - row.index * pagination?.currentPage}
        </span>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    id: "№",
  };

  const client: IColumnTransactions = {
    Cell: ({ value }) => (
      <div>
        <Link to={`/clients/${value.id}`}>{value.telegramId}</Link>
        {value.affiliate && <p className="mb-0">Affiliate: {value.affiliate}</p>}
      </div>
    ),
    Header: "Client",
    id: "client",
    accessor: "client",
  };

  const amount: IColumnTransactions = {
    Header: "Amount",
    id: "amount",
    accessor: "amount",
  };

  const status: IColumnTransactions = {
    Header: "Status",
    id: "status",
    accessor: "status",
  };

  const createdAt: IColumnTransactions = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
  };

  return [numberIndex, client, amount, status, createdAt];
}
