import React, { useMemo } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../components/items/page-title";
import Field from "../components/form/formik/Field";
import { createFields, initialValues, validationSchema } from "../utils/pairFields";
import { CreatePairType } from "../models/Pair";
import { useCreatePairMutation } from "../api/pairs";
import { useGetCurrenciesQuery } from "../api/currencies";
import { useGetClientsQuery } from "../api/clients";

const CreatePair = () => {
  const navigate = useNavigate();
  const fields = useMemo(() => createFields({
    currenciesQuery: useGetCurrenciesQuery,
    clientsQuery: useGetClientsQuery,
  }), [useGetClientsQuery, useGetCurrenciesQuery]);

  const [createPair, { isLoading }] = useCreatePairMutation();

  const handleSubmit = async (values: CreatePairType) => {
    await createPair(values);
    navigate("/pairs");
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/pairs">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating pair" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                    disabled={isLoading}
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreatePair;
