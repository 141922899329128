import { decamelizeKeys, camelizeKeys, decamelize } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { filterEmpyValues, getBaseQuery, getBaseUrl, ListRes, ModelRes } from "./utils";
import { Client, ClientCreate, ClientCreateRequest, ClientFilter, CloneClient, CloneClientRequest, CreateRequestForm, CreateRequestReq, CreateRequestRes, ISendMessage, ISendMessageRequest, ProgressHistory, ShortEditClient, ShortEditClientRequest } from "../models/Client";
import { OrderByFormType } from "../types/global";

const url = `${getBaseUrl()}/clients`;
const tagType = "clients";

export const clientsApi = createApi({
  reducerPath: "clientsApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getClients: build.query<
      ListRes<Client>,
      { pagination: PaginationQuery, filter?: ClientFilter, searchTerm?: string, orderBy: OrderByFormType }
    >({
      query: ({ pagination, filter, searchTerm, orderBy }) => {
        const data = {
          ...pagination,
          filter: filterEmpyValues({
            ...filter,
            status: filter?.status?.value,
            progress: filter?.progress?.value,
            messageAt: filter?.messageAt[0] ? filter?.messageAt : "",
            hideNullableMessageAt: filter?.hideNullableMessageAt?.value,
          }),
          searchTerm,
          [orderBy.sort]: 1,
          orderBy: decamelize(orderBy.orderBy.value),
        };
        if (!searchTerm) delete data.searchTerm;

        return {
          url: "list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Client>) =>
        camelizeKeys(response) as ListRes<Client>,
    }),
    getItem: build.query<ModelRes<Client>, string>({
      query: (id) => ({
        url: `${id}/show`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: tagType, id: result?.model.id }],
      transformResponse: (response: ModelRes<Client>) =>
        camelizeKeys(response) as ModelRes<Client>,
    }),
    getHistory: build.query<ListRes<ProgressHistory>, string>({
      query: (id) => ({
        url: `${id}/histories`,
        method: "GET",
      }),
      providesTags: () => [{ type: tagType, id: "history" }],
      transformResponse: (response: ListRes<ProgressHistory>) =>
        camelizeKeys(response) as ListRes<ProgressHistory>,
    }),
    updateItem: build.mutation<void, { id: string, data: ClientCreate }>({
      query: ({ id, data }) => {
        const sendingData: ClientCreateRequest = {
          id,
          retentionId: data.retention?.id || "",
          status: data.status?.value || null,
          name: data.name,
          email: data.email,
          telegramId: data.telegramId,
          username: data.username,
        };
        return {
          url: `${id}/update`,
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    shortUpdateItem: build.mutation<void, { id: string, data: ShortEditClient, type: "list" | "model" }>({
      query: ({ id, data }) => {
        const sendingData: ShortEditClientRequest = {};
        if (data.age) sendingData.age = data.age;
        if (data.username) sendingData.username = data.username;
        if (data.geo) sendingData.geo = data.geo.value;
        if (data.progress) sendingData.progress = data.progress.value;
        if (data.nationality) sendingData.nationality = data.nationality.value;
        if (data.status) sendingData.statusId = data.status.id;
        return {
          url: `${id}/update`,
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: (result, error, arg) => (
        arg.type === "list"
          ? [{ type: tagType, id: "LIST" }]
          : [{ type: tagType, id: arg.id }]
      ),
    }),
    deleteItem: build.mutation<void, string>({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    updateTelegramId: build.mutation<void, { id: string, telegramId: string }>({
      query: (data) => ({
        url: `${data.id}/update`,
        method: "POST",
        body: decamelizeKeys(data),
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    sendMessage: build.mutation<void, ISendMessage>({
      query: (data) => {
        const sendingData: ISendMessageRequest = {
          text: data.text,
          bot: data.bot?.value || "",
          chatId: typeof data.chatId === "object" ? data.chatId?.telegramId || "" : data.customChatId,
        };

        if (data.buttonText || data.buttonUrl) {
          sendingData.buttonUrl = data.buttonUrl;
          sendingData.buttonText = data.buttonText;
        }

        return {
          url: "/send-message",
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    createRequest: build.mutation<CreateRequestRes, { id: string } & CreateRequestForm>({
      query: ({ id, domain }) => {
        const sendingData: CreateRequestReq = {
          domain: domain?.domain || "",
        };
        return ({
          url: `${id}/invite-link `,
          method: "POST",
          body: decamelizeKeys(sendingData),
        });
      },
    }),
    cloneClient: build.mutation<ModelRes<Client>, CloneClient>({
      query: (data) => {
        const sendingData: CloneClientRequest = {
          clientId: data.clientId,
          cloneId: data.clone?.id || "",
        };

        return {
          url: "/clone",
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: tagType, id: arg.clientId }],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useLazyGetClientsQuery,
  useGetItemQuery,
  useUpdateItemMutation,
  useShortUpdateItemMutation,
  useDeleteItemMutation,
  useUpdateTelegramIdMutation,
  useSendMessageMutation,
  useCreateRequestMutation,
  useCloneClientMutation,
  useLazyGetHistoryQuery,
  useGetHistoryQuery,
} = clientsApi;
