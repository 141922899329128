import { decamelizeKeys, camelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { filterEmpyValues, getBaseQuery, getBaseUrl, ListRes, ModelRes } from "./utils";
import { ChannelCreate, Channel, ChannelFilter } from "../models/Channel";

const url = `${getBaseUrl()}/channels`;
const tagType = "channels";

export const channelsApi = createApi({
  reducerPath: "channelsApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getChannels: build.query<
      ListRes<Channel>,
      { pagination?: PaginationQuery, filter?: ChannelFilter, searchTerm?: string }
    >({
      query: ({ pagination, filter, searchTerm }) => {
        const data = {
          ...pagination,
          filter: filterEmpyValues(filter),
          searchTerm,
        };
        if (!searchTerm) delete data.searchTerm;

        return {
          url: "list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Channel>) =>
        camelizeKeys(response) as ListRes<Channel>,
    }),
    getItem: build.query<ModelRes<Channel>, string>({
      query: (id) => ({
        url: id,
        method: "GET",
      }),
      transformResponse: (response: ModelRes<Channel>) =>
        camelizeKeys(response) as ModelRes<Channel>,
    }),
    createItem: build.mutation<void, ChannelCreate>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: decamelizeKeys(data),
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    updateItem: build.mutation<void, { id: string, data: ChannelCreate }>({
      query: ({ id, data }) => ({
        url: "",
        method: "POST",
        body: decamelizeKeys({ ...data, id }),
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    deleteItem: build.mutation<void, string>({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useGetChannelsQuery,
  useLazyGetChannelsQuery,
  useGetItemQuery,
  useCreateItemMutation,
  useUpdateItemMutation,
  useDeleteItemMutation,
} = channelsApi;
