import React from "react";
import { useField } from "formik";
import { FormControl } from "react-bootstrap";
import { UnknownRecord } from "../../../types/global";
import { IField } from "../../../types/IField";

const TextField = <T extends UnknownRecord>(props: IField<T>) => {
  const { label, prefix, name, placeholder, type } = props;
  const fieldName = prefix ? `${name}.${prefix}` : name;
  const [field, meta] = useField(fieldName);
  const { touched, error } = meta;

  return (
    <>
      {label && <label>{label}</label>}
      <FormControl
        {...field}
        name={fieldName}
        placeholder={placeholder}
        type={type}
        isInvalid={!!(touched && error)}
      />
      {touched && error ? (
        <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
      ) : null}
    </>
  );
};

export default TextField;
