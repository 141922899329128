import { decamelizeKeys, camelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { filterEmpyValues, getBaseQuery, getBaseUrl, ListRes, ModelRes } from "./utils";
import { ClonePairs, CreatePairType, CreatePairTypeRequest, Pair, PairFilter, PairFilterRequest, PairModel, UpdatePairTypeRequest } from "../models/Pair";

const url = `${getBaseUrl()}/pairs`;
const tagType = "pairs";

export const pairsApi = createApi({
  reducerPath: "pairsApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getPairs: build.query<
      ListRes<Pair>,
      { pagination: PaginationQuery; filter: PairFilter }
    >({
      query: ({ pagination, filter }) => {
        const sendingFilter: PairFilterRequest = {
          domain: filter.domain,
          currencyFromId: filter.currencyFrom?.id || "",
          currencyToId: filter.currencyTo?.id || "",
          type: filter.type?.value || "",
          clientId: filter.client?.id || "",
        };
        const data = {
          ...pagination,
          filter: filterEmpyValues(sendingFilter),
          orderBy: "domain"
        };

        return {
          url: "list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Pair>) => {
        const res = camelizeKeys(response) as ListRes<Pair>;
        return {
          pagination: res.pagination,
          list: res.list.map((item) => ({
            ...item,
            isSelectedPair: item.isSelected,
            isSelected: false,
          })),
        };
      },
    }),
    getPair: build.query<ModelRes<PairModel>, string>({
      query: (id) => ({
        url: id,
        method: "GET",
      }),
      transformResponse: (response: ModelRes<PairModel>) =>
        camelizeKeys(response) as ModelRes<PairModel>,
    }),
    createPair: build.mutation<void, CreatePairType>({
      query: (data) => {
        const sendingData: CreatePairTypeRequest = {
          domain: data.domain,
          percent: data.percent,
          type: data.type?.value || null,
          clientId: data.client?.id || null,
          currencyFromId: data.currencyFrom?.id || null,
          currencyToId: data.currencyTo?.id || null,
          isSelected: !!data.isSelectedPair,
          available: data.available,
          max: data.max,
          min: data.min,
        };
        return {
          url: "",
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    updatePair: build.mutation<void, { id: string, data: CreatePairType }>({
      query: ({ id, data }) => {
        const sendingData: UpdatePairTypeRequest = {
          domain: data.domain,
          percent: data.percent,
          type: data.type?.value || null,
          clientId: data.client?.id || null,
          currencyFromId: data.currencyFrom?.id || null,
          currencyToId: data.currencyTo?.id || null,
          isSelected: !!data.isSelectedPair,
          available: data.available,
          max: data.max,
          min: data.min,
          id,
        };

        return {
          url: "",
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    deletePair: build.mutation<void, string>({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    clonePairs: build.mutation<void, ClonePairs>({
      query: (data) => ({
        url: "clone",
        method: "POST",
        body: decamelizeKeys(data),
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useGetPairQuery,
  useLazyGetPairsQuery,
  useCreatePairMutation,
  useUpdatePairMutation,
  useDeletePairMutation,
  useClonePairsMutation,
} = pairsApi;
