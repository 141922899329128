import { IAllFields, TypesOfInput } from "../types/IField";
import { StatisticClientsFilter, StatisticDiagramFilter, StatisticFilter } from "../models/Statistic";
import {OptionsQuery, TypeOptions} from "../types/global";
import {boolOptions, ISelect} from "../types/ISelect";

const today = new Date();
const prevWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

export const emptyStatisticsFilterValues: StatisticFilter = {
  link: "",
  source: "",
  affiliate: "",
  client: null,
  hideNullableClient: null,
};

type Props = {
  clientsQuery: OptionsQuery;
}

export const getFilterFields = ({ clientsQuery }: Props): IAllFields<StatisticFilter>[] => [
  {
    name: "link",
    type: TypesOfInput.TEXT,
  },
  {
    query: clientsQuery,
    name: "client",
    type: TypesOfInput.SELECT,
    labelName: "telegramId"
  },
  {
    name: "source",
    type: TypesOfInput.TEXT,
  },
  {
    name: "affiliate",
    type: TypesOfInput.TEXT,
  },
  {
    name: "hideNullableClient",
    label: "Hide Nullable Client",
    type: TypesOfInput.SELECT,
    options: boolOptions as ISelect<TypeOptions<boolean | null>>["options"],
  }
];

export const emptyStatisticsClientFilterValues: StatisticClientsFilter = {
  messages: "",
  affiliate: "",
  clients: "",
  createdAt: [prevWeek, today]
};

export const getFilterClientsFields = (): IAllFields<StatisticClientsFilter>[] => [
  {
    name: "affiliate",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Created",
    name: "createdAt",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
];

export const emptyStatisticsDiagramFilterValues: StatisticDiagramFilter = {
  createdAt: [prevWeek, today]
};

export const getFilterDiagramFields = (): IAllFields<StatisticDiagramFilter>[] => [
  {
    label: "Created",
    name: "createdAt",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
];
