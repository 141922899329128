import React, { useMemo, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button, Modal } from "react-bootstrap";
import Field from "../form/formik/Field";
import { TypesOfInput } from "../../types/IField";

type TypeOfNames = string[];

interface Props {
  show: boolean;
  onClose: VoidFunction;
  columnsName: TypeOfNames;
  hiddenColumnsName: TypeOfNames;
  handleSaveHiddenColumns: (items: TypeOfNames) => void;
}

type FormikValue = { [key: string]: boolean };

const TableSettings = ({
  show,
  onClose,
  columnsName,
  hiddenColumnsName,
  handleSaveHiddenColumns,
}: Props) => {
  const formikRef: React.Ref<FormikProps<FormikValue>> | null = useRef(null);

  const handleSubmit = (values: FormikValue) => {
    const result: TypeOfNames = [];
    Object.entries(values).forEach(([key, value]) => !value && result.push(key));
    handleSaveHiddenColumns(result);
    onClose();
  };

  const initialValues = useMemo(
    () =>
      columnsName.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue]: !hiddenColumnsName.some((item) => item === currentValue),
        }),
        {},
      ),
    [show],
  );

  const fields = useMemo(() => Object.keys(initialValues), [columnsName]);

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formikRef}>
          <Form className="w-100 px-4">
            <div className="row">
              {fields.map((field) => (
                <Field key={field} name={field} type={TypesOfInput.CHECKBOX} />
              ))}
            </div>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => formikRef.current?.handleSubmit()}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TableSettings;
