import React from "react";
import { Formik, Form } from "formik";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useAppDispatch } from "../hooks/redux";
import { useCreateCommentMutation } from "../api/transactions";
import { TypesOfInput } from "../types/IField";
import Field from "./form/formik/Field";
import { TagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

interface Props {
  clientId: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  payload: ReadonlyArray<TagDescription<"clients">>
}

const CreateCommentModal = ({ setShowModal, showModal, clientId, payload }: Props) => {
  const dispatch = useAppDispatch();
  const [createComment, { isLoading }] = useCreateCommentMutation();
  const handleCreateComment = ({ text }: { text: string }) => {
    createComment({ text, clientId }).then(() => {
      setShowModal(false);
      dispatch({
        type: "clientsApi/invalidateTags",
        payload,
     });
    });
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Creating comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
            initialValues={{
              text: "",
            }}
            validationSchema={Yup.object().shape({
              text: Yup.string().required("Required"),
            })}
            onSubmit={handleCreateComment}
        >
          <Form>
            <Field type={TypesOfInput.TEXT} name="text" size={12} />
            <Button type="submit" disabled={isLoading}>
              Create
            </Button>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCommentModal;
