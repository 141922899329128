import React from "react";
import cn from "classnames";

interface Props {
  status: boolean;
}

const Label = ({ status }: Props) => (
  <div className={cn("Label d-inline", { "Label--active": status })}>{status ? "On" : "Off"}</div>
);

export default Label;
