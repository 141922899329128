import { camelizeKeys, decamelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { filterEmpyValues, getBaseQuery, getBaseUrl, ListRes } from "./utils";
import { Currency } from "../models/Currency";
import { PaginationQuery } from "../components/tables/types";

const url = `${getBaseUrl()}/currencies`;
const tagType = "currencies";

export const currenciesApi = createApi({
  reducerPath: "currenciesApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getCurrencies: build.query<
      ListRes<Currency>,
      { pagination: PaginationQuery, filter?: unknown, searchTerm?: string }
    >({
      query: (body) => {
        const data = {
          ...body.pagination,
          filter: filterEmpyValues(body.filter || {}),
        };

        return {
          url: "list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Currency>) =>
        camelizeKeys(response) as ListRes<Currency>,
    }),
    getCurrenciesToGenerateWallet: build.query<
      ListRes<Currency>,
      { pagination: PaginationQuery, filter?: unknown, searchTerm?: string }
    >({
      query: () => ({
        url: "list",
        method: "POST",
        body: {
          filter: {
            is_generate: 1
        }
        }
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Currency>) =>
        camelizeKeys(response) as ListRes<Currency>,
    }),
  }),
});

export const {
  useGetCurrenciesQuery,
  useLazyGetCurrenciesQuery,
  useGetCurrenciesToGenerateWalletQuery,
} = currenciesApi;
