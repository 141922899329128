import React from "react";
import cn from "classnames";
import { useField } from "formik";
import { FormCheck, FormGroup } from "react-bootstrap";
import { UnknownRecord } from "../../../types/global";
import { IField, TypesOfInput } from "../../../types/IField";

const CheckboxField = <T extends UnknownRecord>({ name, ...props }: IField<T>) => {
  const [field] = useField(name);
  const { value } = field;

  return (
    <FormGroup>
      <label className={cn("Checkbox", { "Checkbox--active": value })}>
        <FormCheck
          {...field}
          label={props.label}
          placeholder={props.placeholder}
          name={name}
          type={TypesOfInput.CHECKBOX}
          checked={value}
        />
        <div className="Checkbox__lable">{value ? "On" : "Off"}</div>
      </label>
    </FormGroup>
  );
};

export default CheckboxField;
