import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../types/IField";
import { BotLinkCreate, BotLinkFilter } from "../models/BotLink";
import { OptionsQuery, YupValidationFields } from "../types/global";

export const emptyFilterValues: BotLinkFilter = {
  bot: null,
  pixel: null,
  user: null,
};

export const emptyInitialValues: BotLinkCreate = {
  ...emptyFilterValues,
};

export const validationSchema = Yup.object<YupValidationFields<BotLinkCreate>>({
  bot: Yup.object().typeError("Required"),
  pixel: Yup.object().typeError("Required"),
  user: Yup.object().typeError("Required"),
});

type Props = {
  usersQuery: OptionsQuery;
  pixelsQuery: OptionsQuery;
  botsQuery: OptionsQuery;
}

export const getCreateFields = ({
  usersQuery,
  pixelsQuery,
  botsQuery,
}: Props): IAllFields<BotLinkCreate>[] => [
  {
    query: botsQuery,
    name: "bot",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name"
  },
  {
    query: pixelsQuery,
    name: "pixel",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "pixel"
  },
  {
    query: usersQuery,
    name: "user",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name"
  },
];

export const getFilterFields = (props: Props): IAllFields<BotLinkFilter>[] => getCreateFields(props);
