import React, { useEffect, useState, useMemo } from "react";
import { useField } from "formik";
import Select, { MultiValue, PropsValue } from "react-select";
import { ISelect } from "../../../../types/ISelect";
import useDebounce from "../../../../hooks/useDebounce";
import { FixMeLater, PAGINATION, UnknownRecord, defaultOrderByFilter } from "../../../../types/global";

const AsyncMultipleSelect = <T extends UnknownRecord, O extends UnknownRecord = any>(props: ISelect<T, O>) => {
  const { query, name, valueName, formatOptions, withoutPagination, isAllButton } = props;
  const [field, , helpers] = useField<MultiValue<string>>(name);
  const { setValue } = helpers;
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce<string>(search, 500);
  const [wasOpened, setWasOpened] = useState(false);
  const queryData = query({
    pagination: withoutPagination ? null : PAGINATION,
    searchTerm: debouncedSearch,
    orderBy: defaultOrderByFilter,
  });

  const options = useMemo(() => {
    let result: FixMeLater[] = [];
    if (!queryData.data) return result;
    result = queryData.data.list;
    if (formatOptions) result = formatOptions(result);
    if (isAllButton) result = [{ value: 'all', label: 'All' }, ...result];    
    return result;
  }, [queryData.data, formatOptions, isAllButton]);
  
  useEffect(() => {
    if (!debouncedSearch && !wasOpened) return;
    queryData.refetch();
  }, [debouncedSearch]);

  const handleFetchOptions = () => {
    setWasOpened(true);
    if (!!queryData && !wasOpened) {
      queryData.refetch();
    }
  };

  const findValue = (): MultiValue<UnknownRecord> => {
    if (!field.value) return [];
    return options.filter((option: O) => field.value.includes(option?.[props.valueName] as string));
  };

  return (
    <Select
      {...field}
      {...props}
      isMulti
      closeMenuOnSelect={false}
      value={findValue() as PropsValue<T>}
      onChange={(selectedValue: MultiValue<UnknownRecord>) => {
        if (!selectedValue.length) return setValue([]);
        if (isAllButton && selectedValue.find((option) => option.value === "all")) {
          return setValue(options.slice(1).map((item) => item[valueName]));
        }
        setValue(selectedValue.map((item) => item[valueName] as string));
      }}
      onMenuOpen={handleFetchOptions}
      onInputChange={setSearch}
      isLoading={queryData.isLoading && wasOpened}
      options={options}
      hideSelectedOptions
    />
  );
};

export default AsyncMultipleSelect;
