import { TransactionStatus, statusOptions } from "../models/Transaction";
import { LabelColors, ObjectWithKeys, TypeOptions, WeekDays } from "../types/global";
import { IField } from "../types/IField";

const dateFormatter = new Intl.DateTimeFormat("el-GR", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
});
const dateFormatterToState = new Intl.DateTimeFormat("zh-CN", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
});
const dateFormatterNormal = new Intl.DateTimeFormat("fr-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
const timeFormatter = new Intl.DateTimeFormat("ua", { hour: "numeric", minute: "numeric" });
const timeFormatterWithSeconds = new Intl.DateTimeFormat("ua", {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export const formatDateToState = (
  dateOrStr: Date | string | number | null = new Date(),
): string | null => {
  if (dateOrStr === null) return null;

  const date =
    typeof dateOrStr === "string" || typeof dateOrStr === "number"
      ? new Date(dateOrStr)
      : dateOrStr;

  if (!isValidDate(date)) return null;

  return dateFormatterToState.format(date);
};

export const formatDate = (
  dateOrStr: Date | string | number | null = new Date(),
): string | null => {
  if (dateOrStr === null) return null;

  const date =
    typeof dateOrStr === "string" || typeof dateOrStr === "number"
      ? new Date(dateOrStr)
      : dateOrStr;

  if (!isValidDate(date)) return null;

  return dateFormatter.format(date);
};

export const formatDateToBack = (
  dateOrStr: Date | string | number | null = new Date(),
): string | null => {
  if (dateOrStr === null) return null;

  const date =
    typeof dateOrStr === "string" || typeof dateOrStr === "number"
      ? new Date(`20${dateOrStr}`)
      : dateOrStr;
  if (!isValidDate(date)) return null;

  return dateFormatterNormal.format(date);
};

export const formatTime = (time: Date): string => {
  if (!isValidDate(time)) return "";
  return timeFormatter.format(time);
};

export const formatTimeWithSeconds = (time: Date): string => {
  if (!isValidDate(time)) return "";
  return timeFormatterWithSeconds.format(time);
};

export const formatDateAndTime = (dateOrStr: Date | string = new Date()): string => {
  const date = typeof dateOrStr === "string" ? new Date(dateOrStr) : dateOrStr;
  if (!isValidDate(date)) return "";
  return `${dateFormatter.format(date)} ${timeFormatter.format(date)}`;
};

export const formatDateFullTime = (dateOrStr: Date | string = new Date()): string => {
  const date = typeof dateOrStr === "string" ? new Date(dateOrStr) : dateOrStr;
  if (!isValidDate(date)) return "";
  return `${dateFormatter.format(date)} ${timeFormatterWithSeconds.format(date)}`;
};

export const formatOptions = <V extends string = string>(keys: V[]): TypeOptions<V>[]  =>
  keys.map((key) => ({
    value: key,
    label: capitalizeFirstLetter(key),
  }));

export const weekDays: WeekDays[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const getCurrentWeekDay = (): WeekDays => {
  const day = new Date().getDay();
  return weekDays[day];
};

export const stringToDate = (str: string | null): Date | null => {
  if (typeof str !== "string") return str;
  const date = new Date(`20${str}`);
  if (!isValidDate(date)) return null;
  return date;
};

const isValidDate = (dateObject: Date) => new Date(dateObject).toString() !== "Invalid Date";

export const stringTimeToDate = (str: string): Date | null => {
  if (typeof str !== "string") return str;
  if (str.length > 10) new Date(str);
  const times: string[] = str.split(":");
  const date = new Date();

  date.setHours(Number(times[0]));
  date.setMinutes(Number(times[1]));
  // date.setSeconds(Number(times[2]));
  date.setSeconds(0);
  if (!isValidDate(date)) return null;
  return date;
};

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const fieldsConstructor = <M>(fieldNames: (keyof M | IField<M>)[]): IField<M>[] => {
  return fieldNames.map((field) => (typeof field === "string" ? { name: field } : field));
};

export const getLocalizationsFromData = (object: ObjectWithKeys, keys: string[]): string[] => {
  const localizationsArray: string[] = [];

  keys.forEach((key) => {
    if (object[key]) {
      localizationsArray.push(...Object.keys(object[key]));
    }
  });
  return Array.from(new Set(localizationsArray));
};

export const getStatusColor = (status: TransactionStatus): LabelColors => {
  const currStatus = statusOptions.find(item => item.value === status);
  return currStatus?.color || LabelColors.GRAY;
};
