import { camelizeKeys, decamelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { getBaseFormQuery, getBaseUrl, ListRes, ModelRes } from "./utils";
import { BotLink, BotLinkCreate, BotLinkCreateRequest, BotLinkFilter } from "../models/BotLink";

const url = `${getBaseUrl()}/bot-links`;
const tagType = "botLinks";

export const botLinksApi = createApi({
  reducerPath: "botLinksApi",
  tagTypes: [tagType],
  baseQuery: getBaseFormQuery(url),
  endpoints: (build) => ({
    getBotLinks: build.query<
      ListRes<BotLink>,
      { pagination: PaginationQuery, filter?: BotLinkFilter, searchTerm?: string }
    >({
      query: ({ searchTerm }) => ({
        url: "list",
        method: "POST",
        body: decamelizeKeys({ searchTerm }),
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<BotLink>) =>
        camelizeKeys(response) as ListRes<BotLink>,
    }),
    getItem: build.query<ModelRes<BotLink>, string>({
      query: (id) => ({
        url: id,
        method: "GET",
      }),
      transformResponse: (response: ModelRes<BotLink>) =>
        camelizeKeys(response) as ModelRes<BotLink>,
      providesTags: (result) => [{ type: tagType, id: result?.model.id }]
    }),
    createItem: build.mutation<void, BotLinkCreate>({
      query(data) {
        const sendingData: BotLinkCreateRequest = {
          botId: data.bot?.id || "",
          pixelId: data.pixel?.id || "",
          userId: data.user?.id || "",
        };
        return {
          url: "",
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    updateItem: build.mutation<ModelRes<BotLink>, { id: string, data: BotLinkCreate }>({
      query: ({ id, data }) => {
        const sendingData: BotLinkCreateRequest = {
          botId: data.bot?.id || "",
          pixelId: data.pixel?.id || "",
          userId: data.user?.id || "",
        };
        return {
          url: id,
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: tagType, id: arg.id }],
    }),
    deleteItem: build.mutation<void, string>({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useGetBotLinksQuery,
  useGetItemQuery,
  useLazyGetBotLinksQuery,
  useUpdateItemMutation,
  useCreateItemMutation,
  useDeleteItemMutation,
} = botLinksApi;
