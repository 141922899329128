import React, { useMemo, useState, useEffect } from "react";

import PageTitle from "../components/items/page-title";
import Table from "../components/tables/Table";
import Loading from "../components/Loading";
import { useLazyGetStatisticsQuery } from "../api/statistics";
import { getColumnDefsStatistics } from "../utils/statisticsColumn";
import { StatisticFilter } from "../models/Statistic";
import { emptyStatisticsFilterValues, getFilterFields } from "../utils/statisticsFields";
import FilterForm from "../components/form/formik/FilterForm";
import { PaginationType } from "../components/tables/types";
import { PAGINATION } from "../types/global";
import { useGetClientsQuery } from "../api/clients";
import StatisticsClients from "../components/StatisticsClients";
import StatisticsTransactions from "../components/StatisticsTransactions";
import StatisticsDiagram from "../components/StatisticsDiagram";

const Statistics = () => {
  const [pagination, setPagination] = useState<PaginationType>(PAGINATION);
  const [filter, setFilter] = useState<StatisticFilter>(emptyStatisticsFilterValues);

  const [fetch, query, { lastArg }] = useLazyGetStatisticsQuery();
  const { data, isLoading } = query;

  useEffect(() => {
    if (data) {
      setPagination({
        ...data.pagination,
        page: data.pagination.currentPage || 1,
      });
    }
  }, [data]);
  useEffect(() => {
    if (
      lastArg.pagination?.page === pagination.page &&
      lastArg.pagination?.take === pagination.take
    ) {
      return;
    }
    fetch({
      pagination: { take: pagination.take, page: pagination.page },
      filter,
    });
  }, [pagination]);
  useEffect(() => {
    fetch({
      pagination: { take: pagination.take, page: pagination.page },
      filter,
    });
  }, [filter]);

  const columns = useMemo(() => getColumnDefsStatistics({ pagination }), [pagination]);
  const filterFields = useMemo(
    () =>
      getFilterFields({
        clientsQuery: useGetClientsQuery,
      }),
    [],
  );

  const handleFilter = (values: StatisticFilter) => {
    setFilter(values);
    return false;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title="Statistics" />
      <StatisticsDiagram />
      <StatisticsTransactions />
      <StatisticsClients />
      <FilterForm
        className="mb-3"
        fields={filterFields}
        handleSubmit={handleFilter}
        initialValues={emptyStatisticsFilterValues}
      />
      <div className="row">
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Table
                setPagination={setPagination}
                className="min-w-screen-lg"
                columns={columns}
                containerClass="overflow-auto"
                fixed
                items={data?.list}
                pagination={pagination}
                dataKey="statistics"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Statistics;
