import React from "react";
import { ErrorMessage } from "formik";

interface Props {
  name: string;
}

const ErrorText = ({ name }: Props) => (
  <ErrorMessage
    name={name}
    render={(errorMessage) => <div className="errorMessage">{errorMessage}</div>}
  />
);

export default ErrorText;
