import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getToken, ModelRes, removeCookies, setCookies } from "../../api/utils";
import { AuthResponse, IUser } from "../../models/Auth";
import { getCurrentUser, login, logout } from "../thunks/auth";

export interface State {
  auth: {
    token: string | null;
    user: IUser | null;
  };
  isAuth: boolean;
}

const initialState: State = {
  auth: {
    token: null,
    user: null,
  },
  isAuth: !!getToken(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    // login
    [login.fulfilled.type]: (state, action: PayloadAction<ModelRes<AuthResponse>>) => {
      state.isAuth = true;
      state.auth = action.payload.model;
      setCookies("authorization", action.payload.model.token);
    },

    // logout
    [logout.fulfilled.type]: (state) => {
      state = { ...initialState };
      removeCookies("authorization");
    },

    // getCurrentUser
    [getCurrentUser.fulfilled.type]: (state, action: PayloadAction<ModelRes<IUser>>) => {
      state.auth.user = action.payload.model;
      // state.auth.user = {
      //   ...action.payload.model,
      //   role: 'manager',
      // }
    },
  },
});

export default authSlice.reducer;
