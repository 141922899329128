import { ReactNode } from "react";
import { UnknownRecord, FixMeLater, OptionsQuery, LabelColors, TypeOptions } from "./global";
import { IField } from "./IField";

export interface ISelect<T, Option = FixMeLater> extends IField<T> {
  formatOptionLabel?: (data: T) => ReactNode;
  options: (Option & UnknownRecord)[];
  labelName?: string;
  valueName: keyof Option;
  isMulti?: boolean;
  isCustomValue?: boolean;
  defaultProps: ISelectDefaultProps;
  query: OptionsQuery;
  formatOptions?: (data: Option[]) => OptionModel<Option>[],
  withoutPagination?: boolean,
  isAllButton?: boolean,
}
export interface ISelectDefaultProps {
  getOptionLabel: (item: UnknownRecord) => string;
  getOptionValue: (item: UnknownRecord) => string;
  isClearable: boolean;
  id: string & keyof UnknownRecord;
}

export type OptionModel<T> = T | null;

export type AsyncObject = {
  fetchCallback: () => FixMeLater;
  isLoading: boolean;
};

export type BoolOptionType = TypeOptions<null | boolean>;
export const boolOptions: BoolOptionType[] = [
  {
    color: LabelColors.BLUE,
    label: "All",
    value: null,
  },
  {
    color: LabelColors.GREEN,
    label: "On",
    value: true,
  },
  {
    color: LabelColors.RED,
    label: "Off",
    value: false,
  },
];

export type BoolOptionsType = OptionModel<BoolOptionType>;
