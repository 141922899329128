import React, { useRef, useState } from "react";
import { FormikProps } from "formik";
import { Button, Modal } from "react-bootstrap";
import { Client, ShortEditClient } from "../models/Client";
import { useShortUpdateItemMutation } from "../api/clients";
import Icon from "./items/Icon";
import ChangeShortUserInfo from "./ChangeShortUserInfo";

interface Props {
  clienId: string;
  values: Client;
}

const EditClientModal = ({ clienId, values }: Props) => {
  const formikRef: React.Ref<FormikProps<ShortEditClient>> | null = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [, { isLoading }] = useShortUpdateItemMutation();

  const onClose = () => setShowModal(false);

  return (
    <>
      <Button
        className="text-xs mb-1"
        size="sm"
        variant="primary"
        onClick={() => setShowModal(true)}
      >
        <Icon name="pencil-1" class="svg-icon-sm" />
      </Button>
      <Modal show={showModal} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>Edit client</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangeShortUserInfo
            values={values}
            clientId={clienId}
            formikRef={formikRef}
            onSuccess={onClose}
            type="list"
            payload={["clients"]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onClose}>
            Close
          </Button>
          <Button
            variant="success"
            disabled={isLoading}
            onClick={() => formikRef.current?.handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditClientModal;
