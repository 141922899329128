import React, { useMemo, useState, useEffect, ChangeEvent } from "react";

import PageTitle from "../components/items/page-title";
import Table from "../components/tables/Table";
import { PaginationType } from "../components/tables/types";
import { OrderByFormType, PAGINATION, defaultOrderByFilter } from "../types/global";
import Loading from "../components/Loading";
import { useLazyGetClientsQuery } from "../api/clients";
import getColumnDefsClients from "../utils/clientsColumn";
import { Client, ClientFilter } from "../models/Client";
import { getFilterFields, initialFilterValues } from "../utils/clientsFields";
import FilterForm from "../components/form/formik/FilterForm";
import SendMessageModal from "../components/SendMessageModal";
import useDebounce from "../hooks/useDebounce";
import { FormControl } from "react-bootstrap";
import { TypesOfInput } from "../types/IField";
import OrderByForm from "../components/form/formik/OrderByForm";

const Clients = () => {
  const [pagination, setPagination] = useState<PaginationType>(PAGINATION);
  const [filter, setFilter] = useState<ClientFilter>(initialFilterValues);
  const [orderFilter, setOrderFilter] = useState<OrderByFormType>(defaultOrderByFilter);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = useDebounce<string>(searchTerm, 500);

  const [fetch, query, { lastArg }] = useLazyGetClientsQuery();
  const { data, isLoading, isFetching } = query;

  useEffect(() => {
    if (data) {
      setPagination({
        ...data.pagination,
        page: data.pagination.currentPage || 1,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      lastArg.pagination?.page === pagination.page &&
      lastArg.pagination?.take === pagination.take
    ) {
      return;
    }

    handleFetch();
  }, [pagination]);

  useEffect(() => {
    handleFetch();
  }, [filter, debouncedSearch]);

  const handleFetch = (values: OrderByFormType = orderFilter) => {
    setOrderFilter(values);
    fetch({
      pagination: { take: pagination.take, page: pagination.page },
      filter,
      searchTerm: debouncedSearch,
      orderBy: values,
    });
  };

  const columns = useMemo(() => getColumnDefsClients({ pagination }), [pagination]);
  const filterFields = useMemo(() => getFilterFields(), []);

  const handleFilter = (values: ClientFilter) => {
    setFilter(values);
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title="Clients" />
      <div className="row">
        <div className="col-3 d-flex d-flex align-items-center mb-3">
          <span className="mr-3">Search:</span>
          <FormControl type={TypesOfInput.TEXT} onChange={handleChangeSearch} value={searchTerm} />
        </div>
        <OrderByForm<Client>
          handleFetch={handleFetch}
          values={orderFilter}
          orderByKeys={[
            "createdAt",
            "email",
            "invite",
            "progress",
            "inviteName",
            "isPremium",
            "isSelected",
            "language",
            "messageAt",
            "name",
            "age",
            "geo",
            "status"
          ]}
        />
      </div>
      <FilterForm
        className="mb-3"
        fields={filterFields}
        handleSubmit={handleFilter}
        initialValues={initialFilterValues}
      />
      <SendMessageModal />
      <div className="row">
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              {isFetching
                ? <Loading />
                : (
                  <Table
                  setPagination={setPagination}
                  className="min-w-screen-xxl"
                  columns={columns}
                  containerClass="overflow-auto"
                  fixed
                  items={data?.list}
                  pagination={pagination}
                  dataKey="clients"
                  />
                )
              }
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Clients;
