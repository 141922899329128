import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef } from "../components/tables/types";
import ColorLabel from "../components/items/ColorLabel";
import { TransactionType } from "../models/Client";
import { statusOptions } from "../models/Transaction";

type IColumn = ColumnDef<TransactionType>;

export const renderAmount = (amount: string | number) =>
  Number(`${amount}`.split(" ")[0]) > 0 ? (
    <span className="color-green">{amount}</span>
  ) : (
    <span className="color-red">{amount}</span>
  );

export default function getTransactionHistory(): IColumn[] {
  const amount: IColumn = {
    Cell: ({ value }) => <div>{renderAmount(value)}</div>,
    Header: "Amount",
    id: "amount",
    accessor: "amount",
    width: "auto",
  };

  const code: IColumn = {
    Header: "Code",
    id: "code",
    accessor: "code",
    width: "auto",
  };

  const domain: IColumn = {
    Header: "Domain",
    id: "domain",
    accessor: "domain",
    width: "auto",
  };

  const status: IColumn = {
    Cell: ({ value }) => <ColorLabel colors={statusOptions} value={value} label={value} />,
    Header: "Status",
    id: "status",
    accessor: "status",
    width: "auto",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "auto",
  };

  return [amount, code, domain, status, createdAt];
}
