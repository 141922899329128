import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { Retention } from "../models/Retention";
import { Link } from "react-router-dom";

type IColumn = ColumnDef<Retention>;

interface Props {
  pagination: PaginationType;
}

export default function getColumnDefsRetentions({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return (
        <div>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - index * pagination?.currentPage}
        </div>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "50px",
    id: "№",
  };

  const comment: IColumn = {
    Header: "Comment",
    id: "comment",
    accessor: "comment",
    width: "auto",
  };

  const text: IColumn = {
    Cell: ({ value, row }) => <Link to={`/retentions/${row.original.id}`}>{value}</Link>,
    Header: "Text",
    id: "text",
    accessor: "text",
    width: "auto",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "auto",
  };

  return [numberIndex, text, comment, createdAt];
}
