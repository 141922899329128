import { ArrayOfDate, LabelColors, ListItem, TypeOptions } from "../types/global";
import { OptionModel } from "../types/ISelect";
import { Client } from "./Client";

export type TransactionStatus = "visited" | "created" | "approved" | "deposited" | "withdrawal" | "canceled" | "error pay" | "retention" | "duplicate" | "waiting" | "fail_paid" | "check_blockchain" | "withdrawal_request";

export type Transaction = ListItem<{
  address: null | string,
  domain: string,
  balance: null | string,
  client: Client,
  code: string,
  currency: Currency,
  status: TransactionStatus
  isAutoPay: 0 | 1,
  logsCount: number,
  sendAt: string,
  depositedAt: string,
}>

export type TransactionFilterCookies = {
  createdAt?: ArrayOfDate,
  statuses?: TransactionStatus[],
  clientId?: OptionModel<string>,
}

export type TransactionFilterForm = {
  addressSending: string,
  hash: string,
  paymentHash: string,
  code: string,
  transactionId: OptionModel<string>
  domain: string,
  statuses?: OptionModel<TransactionStatus>[],
  clientId?: OptionModel<string>,
}

export type TransactionStatusesRequest = {
  createdAt?: ArrayOfDate,
  status?: TransactionStatus[],
  clientId?: OptionModel<string>,
}

export type TransactionRequest = {
  statuses?: OptionModel<TransactionStatus>[],
  createdAt?: ArrayOfDate,
  status?: string,
  clientId?: OptionModel<string>,
  addressSending?: string,
  hash?: string,
  paymentHash?: string,
  code?: string,
  transactionId?: OptionModel<string>
  domain?: string,
}

type Currency = {
  from: string,
  to: string,
}

export const statusOptions: TypeOptions<TransactionStatus>[] = [
  {
    color: LabelColors.BLUE,
    label: "visited",
    value: "visited",
  },
  {
    color: LabelColors.BLUE_LIGHT,
    label: "created",
    value: "created",
  },
  {
    color: LabelColors.ORANGE,
    label: "approved",
    value: "approved",
  },
  {
    color: LabelColors.GREEN,
    label: "deposited",
    value: "deposited",
  },
  {
    color: LabelColors.VIOLET,
    label: "withdrawal",
    value: "withdrawal",
  },
  {
    color: LabelColors.RED,
    label: "canceled",
    value: "canceled",
  },
  {
    color: LabelColors.RED,
    label: "error pay",
    value: "error pay",
  },
  {
    color: LabelColors.GRAY,
    label: "retention",
    value: "retention",
  },
  {
    color: LabelColors.RED,
    label: "Fail paid",
    value: "fail_paid",
  },
];

export type TransactionStatusRes = {
  [key in TransactionStatus]: number
}

export type UpdateTransactionStatuses = "retention" | "withdrawal" | "withdrawal_request"
export type UpdateTransactionData = {
  status?: UpdateTransactionStatuses,
  paymentHash?: string,
  hash?: string,
  percent?: number,
  sendAt?: string | null,
}

export type ChangeStatusType = "cancel" | "withdrawal"

export type TransactionModel = ListItem<Transaction & {
  amount: string,
  depositedAt: number | string,
  hash: string | null,
  logs: Log[]
  logsCount: number,
  paymentHash: string | null,
  percent: number,
  wallet: {
    id: string,
    address: string,
    used: number,
  }
}>

type Log = ListItem<{
  request: unknown,
  action: string,
  message: null | string,
  response: null | string,
}>

export type ManualTransactionCreationType = {
  client: OptionModel<Client>,
  amount: number,
}

export type ManualTransactionCreationRequestType = {
  clientId: Client["id"],
  amount: number,
}

export const manualTransactionCreationValues = {
  client: null,
  amount: 100,
};
