import React, { useMemo, useState, useEffect } from "react";
import PageTitle from "../components/items/page-title";
import Table from "../components/tables/Table";
import { PaginationType } from "../components/tables/types";
import { PAGINATION } from "../types/global";
import Loading from "../components/Loading";
import { useLazyGetCurrenciesQuery } from "../api/currencies";
import getColumnDefsCurrencies from "../utils/currenciesColumn";

const Currencies = () => {
  const [pagination, setPagination] = useState<PaginationType>(PAGINATION);

  const [fetch, query, { lastArg }] = useLazyGetCurrenciesQuery();
  const { data, isLoading } = query;

  useEffect(() => {
    if (data) {
      setPagination({
        ...data.pagination,
        page: data.pagination.currentPage || 1,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      lastArg.pagination?.page === pagination.page &&
      lastArg.pagination?.take === pagination.take
    ) {
      return;
    }

    fetch({
      pagination: { take: pagination.take, page: pagination.page },
    });
  }, [pagination]);

  const columns = useMemo(() => getColumnDefsCurrencies({ pagination }), [pagination]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title="Currencies" />
      <div className="row">
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Table
                setPagination={setPagination}
                className="min-w-screen-lg"
                columns={columns}
                containerClass="overflow-auto"
                fixed
                items={data?.list}
                pagination={pagination}
                dataKey="currencies"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Currencies;
