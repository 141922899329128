import React from "react";
import cn from "classnames";
import { SortableColumnInstance } from "./types";
import { UnknownRecord } from "../../types/global";

interface Clickable {
  onClick: VoidFunction;
}

interface Props<T extends UnknownRecord> {
  column: SortableColumnInstance<T>;
  sortable?: boolean;
}

const HeadCell = <T extends UnknownRecord>({ column, sortable }: Props<T>) => {
  let className = cn("Table-headCell", column.headCellClass, { "text-right": column.alignRight });

  if (!sortable)
    return (
      <th className={className} {...column.getHeaderProps()} style={{ width: column.width }}>
        {column.render("Header")}
      </th>
    );

  const { isSorted, isSortedDesc } = column;
  className = cn(className, "Table-headCell--sortable", {
    "Table-headCell--down": isSorted && isSortedDesc,
    "Table-headCell--up": isSorted && !isSortedDesc,
  });

  return (
    <th
      className={className}
      onClick={(column.getSortByToggleProps() as Clickable).onClick}
      style={{ width: column.width }}
      {...column.getHeaderProps()}
    >
      <div className="Table-headContent">
        {column.render("Header")}
        <svg className="Table-sortMark" width="8" height="10" viewBox="0 0 8 10">
          <path className="Table-sortUp" d="M8 4L4 0L0 4H8Z" />
          <path className="Table-sortDown" d="M0 6L4 10L8 6L0 6Z" />
        </svg>
      </div>
    </th>
  );
};

export default HeadCell;
