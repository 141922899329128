import { decamelizeKeys, camelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { filterEmpyValues, getBaseQuery, getBaseUrl, ListRes } from "./utils";
import { DiagramStatistic, Statistic, StatisticClients, StatisticClientsFilter, StatisticClientsFilterRequest, StatisticDiagramFilter, StatisticDiagramFilterRequest, StatisticFilter, StatisticFilterRequest, StatisticTransactions } from "../models/Statistic";
import { formatDateToBack } from "../utils/formats";
import { Modify } from "../types/global";
import { PaginationQuery } from "../components/tables/types";

const url = `${getBaseUrl()}/statistics`;
const tagType = "statistics";

export const statisticsApi = createApi({
  reducerPath: "statisticsApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getStatistics: build.query<
      ListRes<Statistic>,
      { pagination: PaginationQuery, filter?: StatisticFilter, searchTerm?: string }
    >({
      query: ({ pagination, filter }) => {
        const sendingFilter = {...filter} as Modify<StatisticFilter, { createdAt?: [Date | null, Date | null] }>;
        delete sendingFilter.createdAt;
        const data: StatisticFilterRequest = {
          ...pagination,
          filter: filterEmpyValues({
            link: filter?.link,
            source: filter?.source,
            affiliate: filter?.affiliate,
            "client.email": filter?.client?.email,
            "client.telegram_id": filter?.client?.telegramId,
            hideNullableClient: filter?.hideNullableClient?.value,
          }),
        };

        return {
          url: "list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Statistic>) =>
        camelizeKeys(response) as ListRes<Statistic>,
    }),
    getStatisticsClients: build.query<
      ListRes<StatisticClients>,
      { filter: StatisticClientsFilter, searchTerm?: string }
    >({
      query: ({ filter }) => {
        const sendingFilter = {...filter} as Modify<StatisticClientsFilter, { createdAt?: [Date | null, Date | null] }>;
        delete sendingFilter.createdAt;
        const data: StatisticClientsFilterRequest = {
          filter: {
            ...filterEmpyValues(sendingFilter),
            createdAtStart: formatDateToBack(filter.createdAt[0]) as string,
            createdAtEnd: formatDateToBack(filter.createdAt[1]) as string
          },
        };

        return {
          url: "clients",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST_CLIENTS" },
          ]
          : [{ type: tagType, id: "LIST_CLIENTS" }],
      transformResponse: (response: ListRes<StatisticClients>) =>
        camelizeKeys(response) as ListRes<StatisticClients>,
    }),
    getStatisticsTransactions: build.query<
    ListRes<StatisticTransactions>,
    { pagination: PaginationQuery; }
    >({
      query: ({ pagination }) => ({
          url: "transactions",
          method: "POST",
          body: decamelizeKeys({
            ...pagination,
            filter: {
              status: "retention"
            }
          }),
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST_TRANSACTIONS" },
          ]
          : [{ type: tagType, id: "LIST_TRANSACTIONS" }],
      transformResponse: (response: ListRes<StatisticTransactions>) =>
        camelizeKeys(response) as ListRes<StatisticTransactions>,
    }),
    getDiagramStatistics: build.query<
    DiagramStatistic,
    { filter: StatisticDiagramFilter }
    >({
      query: ({ filter }) => {
        const sendingFilter = {...filter} as { createdAt?: [Date | null, Date | null] };
        delete sendingFilter.createdAt;
        const data: StatisticDiagramFilterRequest = {
          filter: {
            createdAtStart: formatDateToBack(filter.createdAt[0]) as string,
            createdAtEnd: formatDateToBack(filter.createdAt[1]) as string
          },
        };

        return {
          url: "diagram",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: [{ type: tagType, id: "DIAGRAM" }],
      transformResponse: (response: { data: DiagramStatistic }) => camelizeKeys(response.data) as DiagramStatistic,
    }),
  }),
});

export const {
  useLazyGetStatisticsQuery,
  useLazyGetStatisticsClientsQuery,
  useLazyGetStatisticsTransactionsQuery,
  useLazyGetDiagramStatisticsQuery,
} = statisticsApi;
