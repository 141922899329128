import React, { useEffect, useMemo } from "react";
import Icon from "./items/Icon";
import { NavLink } from "react-router-dom";
import { sideBarLinks } from "../App";
import { useAppSelector } from "../hooks/redux";
import { SUPER_ROLES } from "../models/Auth";

interface Props {
  toggleMenu: boolean;
}

const SideBar = ({ toggleMenu }: Props) => {
  const { auth } = useAppSelector((state) => state.authSlice);

  useEffect(() => {
    document.querySelectorAll("[data-bs-toggle='collapse']").forEach((item) => {
      const attribute = item.getAttribute("data-bs-target");
      item.addEventListener("click", () => {
        if (attribute) {
          item.toggleAttribute("aria-expanded");
          document.querySelector(attribute)?.classList.toggle("collapse__list--closed");
        }
      });
    });
  }, []);

  const filteredLinksByRole = useMemo(() => {
    if (!auth.user) return sideBarLinks;
    if (!auth.user.role) return sideBarLinks;
    if (SUPER_ROLES.includes(auth.user.role)) return sideBarLinks;
    return sideBarLinks.filter((item) => item.roles?.includes(auth.user?.role || "admin"));
  }, [auth, sideBarLinks]);

  return (
    <div className={`sidebar py-3 ${toggleMenu ? "shrink show" : ""}`} id="sidebar">
      <h6 className="sidebar-heading">Main</h6>
      <ul className="list-unstyled">
        {filteredLinksByRole.map((item) => (
          <li key={item.name} className="sidebar-list-item">
            <NavLink className="sidebar-link text-muted" to={item.path}>
              <Icon name={item.icon} class="me-3" />
              <span className="sidebar-link-title">{item.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
