import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { UnknownRecord } from "../../../types/global";
import { IField } from "../../../types/IField";
import Icon from "../../items/Icon";
import Field from "./Field";

interface FilterFormProps<T extends UnknownRecord> {
  fields: IField<T>[];
  className?: string;
  handleSubmit: (values: T) => void;
  initialValues: T;
}

const FilterForm = <T extends UnknownRecord>(props: FilterFormProps<T>) => {
  const { fields, className, initialValues, handleSubmit } = props;
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <Button variant="primary" className="mb-3" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
        {isFilterOpen ? "Close" : "Open"} Filters
      </Button>
      {isFilterOpen && (
        <section className={className}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {({ resetForm }) => (
              <Form className="w-100">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {fields.map((field) => (
                        <Field key={field.name} {...field} />
                      ))}
                    </div>
                  </div>
                  <div className="card-footer d-flex">
                    <Button
                      className="form-control w-25 m-auto d-flex justify-center align-items-center"
                      variant="outline-primary"
                      onClick={() => {
                        resetForm();
                        handleSubmit(initialValues);
                      }}
                    >
                      Reset
                      <Icon class="svg-icon-md ml-2" name="repeat" />
                    </Button>
                    <Button
                      type="submit"
                      className="form-control w-25 m-auto d-block"
                      variant="primary"
                    >
                      Filter
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      )}
    </>
  );
};

export default FilterForm;
