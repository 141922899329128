import React from "react";

interface Props {
  title?: string;
}

const PageTitle = ({ title }: Props) => {
  return (
    <div className="page-header d-flex justify-content-between align-items-center">
      <h1 className="page-heading">{title}</h1>
    </div>
  );
};

export default PageTitle;
