import React, { useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { TypesOfInput } from "../types/IField";
import { CreateRequestForm } from "../models/Client";
import { useCreateRequestMutation } from "../api/clients";
import { useGetSitesQuery } from "../api/sites";
import Field from "./form/formik/Field";
import { handleCopy } from "../api/utils";
import Icon from "./items/Icon";

interface Props {
  clienId: string;
}

const CreateRequestForClient = ({ clienId }: Props) => {
  const formikRef: React.Ref<FormikProps<CreateRequestForm>> | null = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [resLink, setResLink] = useState("");
  const [mutate, { isLoading, isError }] = useCreateRequestMutation();

  const handleCreateRequest = async ({ domain }: CreateRequestForm) => {
    try {
      const response = await mutate({ id: clienId, domain }).unwrap();
      if (isError) return;
      setResLink(response.model.link);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button
        className="text-xs mb-1"
        size="sm"
        variant="success"
        onClick={() => setShowModal(true)}
      >
        <Icon name="money-box-1" class="svg-icon-sm" />
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>Create Request</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              domain: null,
            }}
            onSubmit={handleCreateRequest}
            validationSchema={Yup.object({
              domain: Yup.object().typeError("Required"),
            })}
            innerRef={formikRef}
          >
            <Form>
              <Field
                type={TypesOfInput.SELECT}
                name="domain"
                labelName="domain"
                valueName="domain"
                query={useGetSitesQuery}
                size={12}
              />
            </Form>
          </Formik>
          {resLink && (
            <div className="flex justify-between">
              <span>Link: </span>
              <Button variant="success" onClick={() => handleCopy(resLink)} role="button">
                {resLink}
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="success"
            disabled={isLoading}
            onClick={() => formikRef.current?.handleSubmit()}
          >
            Create Request
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateRequestForClient;
