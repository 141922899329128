import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLazyGetHistoryQuery } from "../api/clients";
import Loading from "./Loading";
import ColorLabel from "./items/ColorLabel";
import { formatDate } from "../utils/formats";

interface Props {
  clienId: string;
  progress: string | null;
}

const ClientHistoryModal = ({ clienId, progress }: Props) => {
  const [show, setShow] = useState(false);
  const [fetch, query] = useLazyGetHistoryQuery();
  const { data, isLoading } = query;

useEffect(() => {
    if (!show) return;
    fetch(clienId);
  }, [show]);

  return (
    <div>
      <div onClick={() => setShow(true)}>
        <ColorLabel className="d-inline" label={progress || "No"} />
      </div>
      <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Progress Histories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading || !data ? (
            <Loading />
          ) : (
            <ul>
              {data.list.map((item) => (
                <li key={item.id}>
                  {item.progress} ({formatDate(item.createdAt)}) - {item.user.email}
                </li>
              ))}
            </ul>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ClientHistoryModal;
