import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { BotLink } from "../models/BotLink";
import { Link } from "react-router-dom";

type IColumn = ColumnDef<BotLink>;

interface Props {
  pagination: PaginationType;
}

export default function getColumnDefsBotLinks({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row }) => (
      <span>
        {pagination.total &&
          pagination.currentPage &&
          pagination.total - row.index * pagination?.currentPage}
      </span>
    ),
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    width: "20px",
    id: "№",
  };

  const user: IColumn = {
    Cell: ({ value }) => <div>{value.name}</div>,
    Header: "User",
    id: "user",
    accessor: "user",
    width: "50px",
  };

  const bot: IColumn = {
    Cell: ({ value }) => <Link to="/bots">{value.name}</Link>,
    Header: "Bot",
    id: "bot",
    accessor: "bot",
    width: "50px",
  };

  const pixel: IColumn = {
    Cell: ({ value }) => <Link to="/pixels">{value.pixel}</Link>,
    Header: "Pixel",
    id: "pixel",
    accessor: "pixel",
    width: "50px",
  };

  const idx: IColumn = {
    Header: "Idx",
    id: "idx",
    accessor: "idx",
    width: "50px",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "80px",
  };

  return [numberIndex, user, bot, pixel, idx, createdAt];
}
