import React, { ReactNode, useEffect, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "./hooks/redux";

import "./assets/styles/styles.css";
import "./assets/styles/all.scss";
import "./assets/styles/custom.scss";
import "react-toastify/dist/ReactToastify.min.css";

import AdminLayout from "./layouts/admin-layout";
import LoginPage from "./pages/Login";

import Payments from "./pages/Transactions";
import Pairs from "./pages/Pairs";
import Clients from "./pages/Clients";
import Retentions from "./pages/Retentions";
import CreatePair from "./pages/CreatePair";
import UpdatePair from "./pages/UpdatePair";
import CreateRetention from "./pages/CreateRetention";
import UpdateRetention from "./pages/UpdateRetention";
import UpdateClient from "./pages/UpdateClient";
import CreateWallet from "./pages/CreateWallet";
import UpdateWallet from "./pages/UpdateWallet";
import Wallets from "./pages/Wallets";
import Channels from "./pages/Channels";
import CreateChannel from "./pages/CreateChannel";
import UpdateChannel from "./pages/UpdateChannel";
import WithdrawalWallet from "./pages/WithdrawalWallet";
import Currencies from "./pages/Currencies";
import FakeTransaction from "./pages/FakeTransaction";
import { getCurrentUser } from "./store/thunks/auth";
import Statistics from "./pages/Statistics";
// import SmsTemplates from "./pages/SmsTemplates";
import Invitations from "./pages/Invitations";
import Sites from "./pages/Sites";
import CreateSite from "./pages/CreateSite";
import UpdateSite from "./pages/UpdateSite";
import Bots from "./pages/Bots";
import CreateBot from "./pages/CreateBot";
import Pixels from "./pages/Pixels";
import CreatePixel from "./pages/CreatePixel";
import BotLinks from "./pages/BotLinks";
import Statuses from "./pages/Statuses";
import CreateBotLink from "./pages/CreateBotLink";
import CreateStatus from "./pages/CreateStatus";
import { IUser, SUPER_ROLES, UserRole } from "./models/Auth";
import Loading from "./components/Loading";

type SideBarRouteType = {
  path: string;
  component: ReactNode;
  roles?: UserRole[];
  icon: string;
  name: string,
};

export const sideBarLinks: SideBarRouteType[] = [
  {
    name: "Transactions",
    icon: "money-box-1",
    path: "/transactions",
    component: <Payments />,
    roles: ["affiliate"],
  },
  {
    name: "Pairs",
    icon: "money-box-1",
    path: "/pairs",
    component: <Pairs />,
  },
  {
    name: "Clients",
    icon: "user-1",
    path: "/clients",
    component: <Clients />,
    roles: ["affiliate"],
  },
  {
    name: "Retentions",
    icon: "account-error",
    path: "/retentions",
    component: <Retentions />,
  },
  {
    name: "Wallets",
    icon: "wallet",
    path: "/wallets",
    component: <Wallets />,
  },
  {
    name: "Channels",
    icon: "tv-1",
    path: "/channels",
    component: <Channels />,
  },
  {
    name: "Currencies",
    icon: "dollar-badge-1",
    path: "/currencies",
    component: <Currencies />,
  },
  {
    name: "Fake transaction",
    icon: "question-help-1",
    path: "/fake-transaction",
    component: <FakeTransaction />,
  },
  {
    name: "Statistics",
    icon: "statistic-1",
    path: "/statistics",
    component: <Statistics />,
    roles: ["affiliate"],
  },
  // {
  //   name: "Sms Templates",
  //   icon: "statistic-1",
  //   path: "/sms-templates",
  //   component: <SmsTemplates />,
  // },
  {
    name: "Invitations",
    icon: "text-message-1",
    path: "/invitations",
    component: <Invitations />,
    roles: ["affiliate"],
  },
  {
    name: "Sites",
    icon: "page-1",
    path: "/sites",
    component: <Sites />,
  },
  {
    name: "Bots",
    icon: "support",
    path: "/bots",
    component: <Bots />,
  },
  {
    name: "Pixels",
    icon: "statistic-1",
    path: "/pixels",
    component: <Pixels />,
  },
  {
    name: "Bot links",
    icon: "statistic-1",
    path: "/bot-links",
    component: <BotLinks />,
  },
  {
    name: "Statuses",
    icon: "screwdriver-1",
    path: "/statuses",
    component: <Statuses />,
    roles: ["manager"]
  },
];

const App = () => {
  const dispatch = useAppDispatch();
  const { isAuth, auth } = useAppSelector((state) => state.authSlice);

  useEffect(() => {
    if (isAuth) {
      dispatch(getCurrentUser());
    }
  }, [isAuth]);

  type RouteType = {
    path: string;
    component: ReactNode;
    roles?: UserRole[];
  };

  const adminRoutes: RouteType[] = [
    ...sideBarLinks,
    { path: "/pairs/new", component: <CreatePair /> },
    { path: "/pairs/:id", component: <UpdatePair /> },
    { path: "/retentions/new", component: <CreateRetention /> },
    { path: "/retentions/:id", component: <UpdateRetention /> },
    { path: "/clients/:id", component: <UpdateClient />, roles: ["affiliate"] },
    { path: "/wallets/new", component: <CreateWallet /> },
    { path: "/wallets/:id", component: <UpdateWallet /> },
    { path: "/wallets/withdrawal", component: <WithdrawalWallet /> },
    { path: "/channels/new", component: <CreateChannel /> },
    { path: "/channels/:id", component: <UpdateChannel /> },
    { path: "/sites/new", component: <CreateSite /> },
    { path: "/sites/:id", component: <UpdateSite /> },
    { path: "/bots/new", component: <CreateBot /> },
    { path: "/pixels/new", component: <CreatePixel /> },
    { path: "/bot-links/new", component: <CreateBotLink /> },
    { path: "/statuses/new", component: <CreateStatus />, roles: ["manager"] },
  ];

  const filteredPagesByRole = useMemo(() => {
    if (!auth.user) return adminRoutes;
    return SUPER_ROLES.includes(auth.user.role)
      ? adminRoutes
      : adminRoutes.filter((item) => item.roles?.includes((auth.user as IUser).role));
  }, [auth]);

  if (isAuth && !filteredPagesByRole.length) {
    return (
      <div className="absolute top-50per left-50per">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Routes>
        {isAuth ? (
          <>
            <Route element={<AdminLayout />}>
              {filteredPagesByRole.map(({ path, component }) => (
                <Route key={path} path={path} element={component} />
              ))}
              {filteredPagesByRole.length && (
                <Route path="*" element={<Navigate to="/transactions" replace />} />
              )}
            </Route>
          </>
        ) : (
          <>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
