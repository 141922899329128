import React from "react";
import ReactSelect, { SingleValue } from "react-select";
import { useField } from "formik";
import { ISelect } from "../../../../types/ISelect";
import { FixMeLater, UnknownRecord } from "../../../../types/global";

const SingleSelect = <T extends UnknownRecord>(props: ISelect<T>) => {
  const [field, meta, helpers] = useField<UnknownRecord | null>(props.name);

  const { setValue } = helpers;

  return (
    <ReactSelect
      {...field}
      {...props}
      value={meta.value as FixMeLater}
      isMulti={false}
      onChange={(option: SingleValue<UnknownRecord>) => {
        setValue(option);
      }}
    />
  );
};

export default SingleSelect;
