import { decamelizeKeys, camelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationQuery } from "../components/tables/types";
import { filterEmpyValues, getBaseQuery, getBaseUrl, ListRes, ModelRes } from "./utils";
import { ChangeStatusType, ManualTransactionCreationRequestType, ManualTransactionCreationType, Transaction, TransactionModel, TransactionRequest, TransactionStatusesRequest, TransactionStatusRes, UpdateTransactionData } from "../models/Transaction";
import { CommentType } from "../models/Comment";

const url = `${getBaseUrl()}/`;
const tagType = "transactions";
const tagTypeComments = "comments";

export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  tagTypes: [tagType, tagTypeComments],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getTransactions: build.query<
      ListRes<Transaction>,
      { pagination: PaginationQuery; filter: TransactionRequest }
    >({
      query: ({ pagination, filter }) => {
        const data = {
          ...pagination,
          filter: filterEmpyValues(filter),
        };

        return {
          url: "transactions/list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Transaction>) =>
        camelizeKeys(response) as ListRes<Transaction>,
    }),
    getTransaction: build.query<ModelRes<TransactionModel>, string>({
      query: (id) => ({
        url: `transactions/${id}/show`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: tagType, id: result?.model.id }],
      transformResponse: (response: ModelRes<TransactionModel>) =>
        camelizeKeys(response) as ModelRes<TransactionModel>,
    }),
    getTransactionStatuses: build.query<
      ListRes<TransactionStatusRes>,
      { pagination?: PaginationQuery; filter?: TransactionStatusesRequest, searchTerm?: string }
    >({
      query: ({ filter }) => ({
        url: "transactions/statuses",
        method: "POST",
        body: decamelizeKeys({
          filter: filterEmpyValues(filter),
          type: "query"
        }),
      }),
      providesTags: () => [{ type: tagType, id: "STATUSES" }],
    }),
    updateTransaction: build.mutation<void, { id: string, data: UpdateTransactionData }>({
      query: ({ id, data }) => {
        const sendingsData: UpdateTransactionData = {
          percent: data.percent,
          status: data.status,
        };
        if (data.hash) {
          sendingsData.hash = data.hash;
        }
        if (data.sendAt) {
          sendingsData.sendAt = `20${data.sendAt.replace(/\//g, "-")}:00`;
        }
        if (data.paymentHash) {
          sendingsData.paymentHash = data.paymentHash;
        }
        return ({
          url: `transactions/${id}/update`,
          method: "POST",
          body: decamelizeKeys(sendingsData),
        });
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }, { type: tagType, id: "STATUSES" }],
    }),
    changeStatus: build.mutation<void, { id: string, status: ChangeStatusType }>({
      query: ({ id, status }) => ({
        url: `transactions/${id}/${status}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }, { type: tagType, id: "STATUSES" }],
    }),
    createComment: build.mutation<void, { clientId: string, text: string }>({
      query: (data) => ({
        url: "comments",
        method: "POST",
        body: decamelizeKeys(data)
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }, { type: tagTypeComments, id: "LIST" }],
    }),
    deleteComment: build.mutation<void, string>({
      query: (id) => ({
        url: `comments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: tagTypeComments, id: "LIST" }],
    }),
    resendEmail: build.mutation<void, string>({
      query: (id) => ({
        url: `transactions/${id}/resend-email`,
        method: "POST",
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    getClientComments: build.query<ListRes<CommentType>, string>({
      query: (id) => ({
        url: `clients/${id}/comments`,
        method: "GET",
      }),
      providesTags: [{ type: tagTypeComments, id: "LIST" }],
    }),
    changeStatusToDeposited: build.mutation<void, string>({
      query: (id) => ({
        url: `transactions/${id}/update`,
        method: "POST",
        body: decamelizeKeys({ status: "deposited" }),
      }),
      invalidatesTags: [{ type: tagType, id: "LIST" }, { type: tagType, id: "STATUSES" }],
    }),
    createTransaction: build.mutation<void, ManualTransactionCreationType>({
      query: ({ client, amount }) => {
        const sendingData: ManualTransactionCreationRequestType = {
          amount,
          clientId: client?.id || "",
        };
        return {
          url: "transactions/store",
          method: "POST",
          body: decamelizeKeys(sendingData),
        };
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }, { type: tagType, id: "STATUSES" }],
    }),
  }),
});

export const {
  useLazyGetTransactionsQuery,
  useGetTransactionStatusesQuery,
  useChangeStatusMutation,
  useUpdateTransactionMutation,
  useLazyGetTransactionQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useLazyGetClientCommentsQuery,
  useGetClientCommentsQuery,
  useResendEmailMutation,
  useChangeStatusToDepositedMutation,
  useCreateTransactionMutation,
} = transactionsApi;
