import { LabelColors, ListItem, TypeOptions, YupValidationFields } from "../types/global";
import { BoolOptionsType, OptionModel } from "../types/ISelect";
import { formatOptions } from "../utils/formats";
import { Retention } from "./Retention";
import { Site } from "./Site";
import { TransactionStatus } from "./Transaction";
import * as Yup from "yup";
import { User } from "./User";
import { StatusT } from "./Status";

type ClientStatus = "enabled" | "retention";

type ChannelType = ListItem<{
  channelId: string,
  logo: string,
  name: string,
  username: string | null,
}>

type SubscriptionType = ListItem<{
  channel: ChannelType,
  unsubscribedAt: string | null,
}>

export type TransactionType = ListItem<{
  amount: string,
  code: string,
  domain: string,
  status: TransactionStatus,
}>

export type ShortClient = ListItem<{
  affiliate: string | null,
  age: string | null,
  avatar: string | null,
  balance: number,
  commentsCount: number,
  email: string | null,
  geo: string | null,
  messageAt: string | null,
  name: string | null,
  nationality: string | null,
  progress: string | null,
  source: string | null,
  status: ClientStatus,
  statusInfo: StatusT | null,
  telegramId: string,
  username: string | null,
}>

export type Client = ShortClient & {
  fdDate: string | null,
  invite: string | null
  source: string | null
  inviteLink: string,
  inviteName: string,
  ip: string | null,
  isPremium: 0 | 1,
  language: string | null,
  notificationStatus: 0 | 1,
  retention: Retention | null,
  retentionDate: string | null,
  subscriptions: SubscriptionType[]
  transactions: TransactionType[],
  visit: string,
  pass: string,
  commentsCount: number,
}

export type ClientFilter = {
  email: string,
  username: string,
  affiliate: string,
  status: OptionModel<TypeOptions<ClientStatus>>,
  progress: OptionModel<TypeOptions<string>>,
  telegramId: string,
  inviteLink: string,
  hideNullableMessageAt: BoolOptionsType,
  name: string,
  messageAt: [Date | null, Date | null]
}

export type ClientCreate = {
  name: string,
  email: string,
  status: OptionModel<TypeOptions<ClientStatus>>,
  telegramId: string,
  username: string,
  retention: OptionModel<Retention>
}

export type ClientCreateRequest = {
  name: string,
  email: string,
  telegramId: string,
  username: string,
  id: string,
  retentionId: OptionModel<string>,
  status: OptionModel<ClientStatus>,
}

export const statusOptions: TypeOptions<ClientStatus>[] = [
  {
    color: LabelColors.BLUE,
    label: "Enable",
    value: "enabled",
  },
  {
    color: LabelColors.GREEN,
    label: "Retention",
    value: "retention"
  }
];

export type ClientStatistic = {
  name: string,
  telegramId: string,
  affiliate: string,
  balance: string,
  createdAt: string,
}

export type ISendMessage = {
  chatId: OptionModel<Client>,
  bot: OptionModel<TypeOptions<string>>,
  customChatId: string,
  text: string,
  buttonText?: string
  buttonUrl?: string
}

export type ISendMessageRequest = {
  chatId: string,
  text: string,
  bot: string,
  buttonText?: string
  buttonUrl?: string
}

export const sendMessageInitialValues = {
  chatId: null,
  bot: null,
  customChatId: "",
  text: "",
  buttonText: "",
  buttonUrl: "",
};

export const sendMessageValidationSchema = Yup.object().shape<YupValidationFields<ISendMessage>>(
  {
    chatId: Yup.object().when("customChatId", {
      is: (val: string) => !val,
      then: (schema) => schema.typeError("Required"),
    }),
    customChatId: Yup.string().when("chatId", {
      is: (val: Client) => !val,
      then: (schema) => schema.required("Required"),
    }),
    text: Yup.string().required("Required"),
    buttonText: Yup.string().when("buttonUrl", {
      is: (val: string) => !!val,
      then: (schema) => schema.required("Required"),
    }),
    buttonUrl: Yup.string().when("buttonText", {
      is: (val: string) => !!val,
      then: (schema) => schema.required("Required"),
    }),
  },
  [["buttonText", "buttonUrl"], ["chatId", "customChatId"]],
);

export type CreateRequestForm = {
  domain: OptionModel<Site>
}

export type CreateRequestReq = {
  domain: string,
}

export type CreateRequestRes = {
  model: {
    link: string,
  },
  errors: [],
}

export type CloneClient = {
  clientId: string,
  clone: OptionModel<Client>,
}

export type CloneClientRequest = {
  clientId: string,
  cloneId: Client["id"],
}

export const clientProgressOptions: TypeOptions<string>[] = formatOptions([
  "Отстойник",
  "Регистрация",
  "Дожать",
  "1 этап",
  "2 этап",
  "Депозит",
  "Инвестиции",
  "Реклама",
  "Скамер",
  "Бомж",
  "Малолетка",
  "Украинец",
]);

export const clientGeoOptions: TypeOptions<string>[] = formatOptions([
  "Южная Корея 🇰🇷",
  "сосия 💩",
  "Казахстан 🇰🇿",
  "Таджикистан 🇹🇯",
  "Узбекистан 🇺🇿",
  "Кыргызстан 🇰🇬",
  "Турция 🇹🇷",
  "Финляндия 🇫🇮",
  "Германия 🇩🇪",
  "Испания 🇪🇸",
  "Египет 🇪🇬",
  "ОАЭ 🇦🇪",
  "Молдова 🇲🇩",
  "Грузия 🇬🇪",
  "Армения 🇦🇲",
  "Азербайджан 🇦🇿",
  "Беларусь 🇧🇾",
  "Абхазия",
]);

export const clientNationalityOptions: TypeOptions<string>[] = formatOptions([
  "сосия 💩🤢🤮",
  "Казахстан 🇰🇿",
  "Таджикистан 🇹🇯",
  "Узбекистан 🇺🇿",
  "Кыргызстан 🇰🇬",
  "Турция 🇹🇷",
  "Молдова 🇲🇩",
  "Грузия 🇬🇪",
  "Армения 🇦🇲",
  "Азербайджан 🇦🇿",
  "Беларусь 🇧🇾",
  "Абхазия",
]);

export const botOptions: TypeOptions<string>[] = formatOptions([
  "p2pprivate_bot",
  "VIP_P2P_bot",
]);

export type ShortEditClient = {
  status: OptionModel<StatusT>,
  progress: OptionModel<TypeOptions<string>>,
  nationality: OptionModel<TypeOptions<string>>,
  geo: OptionModel<TypeOptions<string>>,
  age: string,
  username: string,
}

export type ShortEditClientRequest = {
  progress?: string,
  nationality?: string,
  geo?: string,
  statusId?: string,
  username?: string,
  age?: string,
}

export type ProgressHistory = ListItem<{
  progress: string,
  user: ListItem<User>
}>

export const shortEditClientInitialValues: ShortEditClient = {
  username: "",
  age: "",
  status: null,
  progress: null,
  nationality: null,
  geo: null,
};

export const shortEditClientSchema = Yup.object().shape<YupValidationFields<ShortEditClient>>(
  {
    progress: Yup.object().nullable(),
    nationality: Yup.object().nullable(),
    geo: Yup.object().nullable(),
    age: Yup.number(),
  },
);
