import React, { useEffect, useMemo } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button, Row } from "react-bootstrap";
import * as Yup from "yup";
import {
  shortEditClientInitialValues,
  shortEditClientSchema,
  clientProgressOptions,
  clientGeoOptions,
  clientNationalityOptions,
  ShortEditClient,
  Client,
} from "../models/Client";
import { TypesOfInput } from "../types/IField";
import { useShortUpdateItemMutation } from "../api/clients";
import Field from "./form/formik/Field";
import { useCreateCommentMutation } from "../api/transactions";
import { TagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { useAppDispatch } from "../hooks/redux";
import { useLazyGetStatusesQuery } from "../api/statuses";
import { FixMeLater, PAGINATION } from "../types/global";
import Loading from "./Loading";

interface Props {
  clientId: string;
  values: Client;
  formikRef: React.Ref<FormikProps<ShortEditClient>>;
  onSuccess?: VoidFunction;
  type: "list" | "model";
  payload: ReadonlyArray<TagDescription<"clients">>;
}

const ChangeShortUserInfo = ({ clientId, formikRef, type, onSuccess, values, payload }: Props) => {
  const dispatch = useAppDispatch();
  const [mutate] = useShortUpdateItemMutation();
  const [createComment, { isLoading }] = useCreateCommentMutation();
  const [fetch, query] = useLazyGetStatusesQuery();

  const channels = useMemo(() => values.subscriptions.map(({ channel }) => channel), [values]);

  useEffect(() => {
    fetch({ pagination: PAGINATION, filter: { channel: channels } });
  }, [channels]);

  const handleCreateComment = ({ text }: { text: string }) => {
    createComment({ text, clientId }).then(() => {
      dispatch({
        type: "clientsApi/invalidateTags",
        payload,
      });
    });
  };

  const handleSubmit = (data: ShortEditClient) => {
    mutate({ id: clientId, data, type }).unwrap().then(onSuccess);
  };

  const FIELD_SIZE = 12;

  const initialValues = useMemo(
    () => ({
      progress:
        clientProgressOptions.find((option) => option.value === values.progress) ??
        shortEditClientInitialValues.progress,
      status: values.statusInfo,
      nationality:
        clientNationalityOptions.find((option) => option.value === values.nationality) ??
        shortEditClientInitialValues.nationality,
      geo:
        clientGeoOptions.find((option) => option.value === values.geo) ??
        shortEditClientInitialValues.geo,
      age: values.age ?? shortEditClientInitialValues.age,
      username: values.username ?? shortEditClientInitialValues.username,
    }),
    [
      shortEditClientInitialValues,
      clientProgressOptions,
      clientNationalityOptions,
      clientGeoOptions,
      values,
    ],
  );

  if (query.isLoading) return <Loading />;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={shortEditClientSchema}
        innerRef={formikRef}
      >
        <Form>
          <Row>
            <Field type={TypesOfInput.TEXT} name="username" size={FIELD_SIZE} />
            <Field
              type={TypesOfInput.SELECT}
              name="progress"
              options={clientProgressOptions}
              size={FIELD_SIZE}
            />
            <Field
              type={TypesOfInput.SELECT}
              name="status"
              options={query.data?.list as FixMeLater ?? []}
              labelName="name"
              valueName="id"
              size={FIELD_SIZE}
            />
            <Field
              type={TypesOfInput.SELECT}
              name="geo"
              options={clientGeoOptions}
              size={FIELD_SIZE}
            />
            <Field
              type={TypesOfInput.SELECT}
              name="nationality"
              options={clientNationalityOptions}
              size={FIELD_SIZE}
            />
            <Field type={TypesOfInput.NUMBER} name="age" size={FIELD_SIZE} />
          </Row>
        </Form>
      </Formik>
      <hr />
      <Formik
        initialValues={{
          text: "",
        }}
        validationSchema={Yup.object().shape({
          text: Yup.string().required("Required"),
        })}
        onSubmit={handleCreateComment}
      >
        <Form>
          <Field label="Comment text" type={TypesOfInput.TEXT} name="text" size={12} />
          <Button className="w-full mb-3" variant="success" type="submit" disabled={isLoading}>
            Creating comment
          </Button>
        </Form>
      </Formik>
    </>
  );
};

export default ChangeShortUserInfo;
