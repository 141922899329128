import { useField } from "formik";
import React, { useState, MouseEvent, useEffect } from "react";
import { FormControl } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { UnknownRecord } from "../../../../types/global";
import { IFileField } from "../../../../types/IField";
import { FileItem } from "../../../items/FileItem";

const FileField = <T extends UnknownRecord>({ label, name, isMulti, prefix }: IFileField<T>) => {
  const fieldName = prefix ? `${name}.${prefix?.toString()}` : name;
  const [field, meta, helpers] = useField(fieldName);
  const { setValue } = helpers;
  const { touched, error } = meta;

  const [fieldFiles, setFieldFiles] = useState<File[] | string[] | File>();

  useEffect(() => {
    if (field.value) {
      if (isMulti) {
        if (Array.isArray(field.value)) {
          setFieldFiles(field.value);
        } else if (typeof field.value === "string") {
          setFieldFiles([field.value]);
        }
      } else {
        setFieldFiles(field.value);
      }
    } else {
      setFieldFiles(field.value);
    }
  }, [field.value]);

  const handleAddFile = (files: File[]) => {
    if (isMulti) {
      setValue([...(fieldFiles as File[]), ...files]);
    } else {
      setValue(files[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    maxSize: 2000000,
    multiple: !!isMulti,
    onDrop: handleAddFile,
  });

  const handleDelete = (event: MouseEvent<HTMLButtonElement>, file: File | string) => {
    event.stopPropagation();
    if (isMulti) {
      if (typeof file === "string") {
        setValue((fieldFiles as string[]).filter((item) => item !== file));
      } else {
        setValue(
          (fieldFiles as File[]).filter(
            (item) => `${item.lastModified}/${item.name}` !== `${file.lastModified}/${file.name}`,
          ),
        );
      }
    } else {
      setValue(null);
    }
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="FileField__images">
          {isMulti && fieldFiles && (fieldFiles as File[]).length ? (
            (fieldFiles as File[]).map((file: File | string) => (
              <FileItem
                key={typeof file === "string" ? file : file.name}
                file={file}
                onDelete={handleDelete}
              />
            ))
          ) : !isMulti && fieldFiles ? (
            <FileItem
              key={typeof fieldFiles === "string" ? fieldFiles : (fieldFiles as File).name}
              file={fieldFiles as File}
              onDelete={handleDelete}
            />
          ) : (
            <p> Drag&apos;n drop file, or click to select file</p>
          )}
        </div>
      </div>
      {touched && error ? (
        <>
          <span className="is-invalid" />
          <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
        </>
      ) : null}
    </>
  );
};

export default FileField;
