import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../types/IField";
import { BotCreate, BotFilter } from "../models/Bot";
import { YupValidationFields } from "../types/global";

export const emptyFilterValues: BotFilter = {
  name: "",
  token: "",
  link: "",
};

export const emptyInitialValues: BotCreate = {
  message: ""
};

export const validationSchema = Yup.object<YupValidationFields<BotCreate>>({
  message: Yup.string().required("Required"),
});

export const getCreateFields = (): IAllFields<BotCreate>[] => [
  {
    name: "message",
    type: TypesOfInput.TEXTAREA,
  },
];

export const getFilterFields = (): IAllFields<BotFilter>[] => [
  {
    name: "name",
    type: TypesOfInput.TEXT,
  },
  {
    name: "token",
    type: TypesOfInput.TEXT,
  },
  {
    name: "link",
    type: TypesOfInput.TEXT,
  },
];
