import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { StatusT } from "../models/Status";

type IColumn = ColumnDef<StatusT>;

interface Props {
  pagination: PaginationType;
}

export default function getColumnDefsStatuses({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row }) => (
      <span>
        {pagination.total &&
          pagination.currentPage &&
          pagination.total - row.index * pagination?.currentPage}
      </span>
    ),
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    width: "20px",
    id: "№",
  };

  const title: IColumn = {
    Header: "Title",
    id: "title",
    accessor: "title",
    width: "80px",
  };

  const name: IColumn = {
    Header: "Name",
    id: "name",
    accessor: "name",
    width: "80px",
  };

  const channel: IColumn = {
    Cell: ({ value }) => <span>{value.name}</span>,
    Header: "Channel",
    id: "channel",
    accessor: "channel",
    width: "80px",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "80px",
  };

  return [numberIndex, name, title, channel, createdAt];
}
