import React from "react";
import { formatDateAndTime } from "./formats";
import { ColumnDef, PaginationType } from "../components/tables/types";
import { Channel } from "../models/Channel";
import { handleCopy } from "../api/utils";

type IColumn = ColumnDef<Channel>;

interface Props {
  pagination: PaginationType;
}

export default function getColumnDefsChannels({ pagination }: Props): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row }) => {
      return (
        <span>
          {pagination.total &&
            pagination.currentPage &&
            pagination.total - row.index * pagination?.currentPage}
        </span>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    accessor: "id",
    width: "60px",
    id: "№",
  };

  const channelId: IColumn = {
    Cell: ({ value }) => (
      <div className="p-1 btn-link">
        <span onClick={() => handleCopy(value || "")} role="button">
          {value}
        </span>
      </div>
    ),
    Header: "Channel Id",
    id: "channelId",
    accessor: "channelId",
    width: "130px",
  };

  const cr: IColumn = {
    Header: "Cr",
    id: "cr",
    accessor: "cr",
    width: "50px",
  };

  const cvr: IColumn = {
    Header: "Cvr",
    id: "cvr",
    accessor: "cvr",
    width: "50px",
  };

  const dialogs: IColumn = {
    Header: "Dialogs",
    id: "dialogs",
    accessor: "dialogs",
    width: "70px",
  };

  const fd: IColumn = {
    Header: "Fd",
    id: "fd",
    accessor: "fd",
    width: "50px",
  };

  const logo: IColumn = {
    Cell: ({ value }) => <img className="svg-icon-lg" src={value} alt="qr" />,
    Header: "logo",
    id: "logo",
    width: "80px",
    accessor: "logo",
  };

  const name: IColumn = {
    Header: "name",
    id: "name",
    accessor: "name",
  };

  const retention: IColumn = {
    Header: "retention",
    id: "retention",
    width: "80px",
    accessor: "retention",
  };

  const revenue: IColumn = {
    Header: "revenue",
    id: "revenue",
    width: "80px",
    accessor: "revenue",
  };

  const subscribers: IColumn = {
    Header: "subscribers",
    id: "subscribers",
    accessor: "subscribers",
    width: "80px",
  };

  const username: IColumn = {
    Header: "username",
    id: "username",
    accessor: "username",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateAndTime(value)}</span>,
    Header: "Created",
    id: "Created",
    accessor: "createdAt",
    width: "100px",
  };

  return [
    numberIndex,
    channelId,
    cr,
    cvr,
    dialogs,
    fd,
    logo,
    name,
    retention,
    revenue,
    subscribers,
    username,
    createdAt,
  ];
}
