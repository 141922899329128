import { LabelColors, ListItem, Modify, TypeOptions } from "../types/global";

export type Site = ListItem<{
  domain: string,
  logo: string,
  name: string,
  support: string,
  status: boolean,
}>

export type SiteFilter = {
  domain: string,
  name: string,
  support: string,
  status: TypeOptions<boolean>,
}

export type SiteCreate = SiteFilter & {
  logo: File | null | string,
}

export type SiteCreateRequest = Modify<SiteCreate, {
  status: number,
}>

export const statusOptions: TypeOptions<boolean>[] = [
  {
    color: LabelColors.GREEN,
    label: "On",
    value: true,
  },
  {
    color: LabelColors.RED,
    label: "Off",
    value: false,
  },
];
